import { combine, createEvent, createStore } from "effector";
import { onSnapshot, collection, getFirestore, setDoc, doc } from "firebase/firestore";
import _ from 'lodash';
import * as isEqual from 'lodash/isEqual';

import { decodeOverlay, EncodedImageOverlay, encodeOverlay, ImageOverlay } from "../../../functions/src/imageOverlay";
import { authStore, AuthStore } from "../../lib/auth/store";
import log from "../../lib/log";
import { activeMapStore, ActiveMapStore } from "../../pages/mapEdit/store";
import { imageOverlayDrawComplete } from "./ImageOverlay";

export interface ImageOverlayStore {
    [id:string]: ImageOverlay
}

export const resetStore = createEvent();
const overlayPendingSave = createEvent<string>();
const overlaySaved = createEvent<[string, ImageOverlay]>();

const serverEvents = {
    added: createEvent<[string, EncodedImageOverlay]>(),
    modified: createEvent<[string, EncodedImageOverlay]>(),
    removed: createEvent<[string, EncodedImageOverlay]>()
};

const decodedServerEvents = Object.fromEntries(Object.entries(serverEvents).map(([key, event]) => {
    return [key, event.map(([id, encodedOverlay]) => ({id, overlay: decodeOverlay(encodedOverlay)}))];
}));

export const overlayStore = createStore<ImageOverlayStore>({}).reset(resetStore);

overlayStore.on(decodedServerEvents.added, (state, event) => {
    const {id, overlay} = event;
    const newOverlay:ImageOverlay = {
        ...overlay,
        visible: false,
        saved: true
    }
    return {
        ...state,
        [id]: newOverlay
    };
});

overlayStore.on(imageOverlayDrawComplete, (state, localOverlayStore) => {
    const oldCopy = state[localOverlayStore.id];

    // if bbox hasn't changed, this was a programmatic draw
    if (isEqual(oldCopy.bboxPoly, localOverlayStore.bboxPoly)) {
        return state;
    }

    const newCopy:ImageOverlay = {
        ...oldCopy,
        center: localOverlayStore.center,
        bboxPoly: localOverlayStore.bboxPoly,
        visible: true,
        saved: false,
        lastModified: Date.now()
    };
    const newState:ImageOverlayStore = {
        ...state,
        [localOverlayStore.id]: newCopy
    };

    return newState;
});


// acknowledge we've saved this to the server
overlayStore.on(overlaySaved, (state, [id, overlay]) => {
    // if local annotation is newer than server, ignore
    const localCopy = state[id];
    const localLastMod = localCopy?.lastModified || 0;
    const oldUpdate = overlay.lastModified <= localLastMod;

    if (oldUpdate) {
        return state;
    }

    const updatedCopy:ImageOverlay = {
        ...overlay,
        pendingSave: false,
        saved: true
    };
    log.debug('overlayStore:overlaySaved', updatedCopy);

    return {...state, [id]: updatedCopy};
});

// acknowledge we've sent a request to save to the server
overlayStore.on(overlayPendingSave, (state, id) => {
    // if local annotation is newer than server, ignore
    const localCopy = state[id];
    const localLastMod = localCopy?.lastModified || 0;
    const oldUpdate = localCopy.lastModified <= localLastMod;

    if (oldUpdate) {
        return state;
    }

    const updatedCopy:ImageOverlay = {
        ...localCopy,
        pendingSave: true
    };
    log.debug('overlayStore:overlayPendingSave', updatedCopy);

    return {...state, [id]: updatedCopy};
});

// save overlays 
type SendToFirebaseParams = [AuthStore, ActiveMapStore, ImageOverlayStore]
const sendToFirebase = ([maybeAuth, activeMapStore, overlayStore]:SendToFirebaseParams) => {
    if (!maybeAuth.data || !activeMapStore.data?.id) {
        return
    }
    const key = `organizations/${maybeAuth.data.orgId}/maps/${activeMapStore.data.id}/imageOverlays`
    // for every overlay update the doc
    Object.entries(overlayStore)
        .filter(([id, overlay]) => !overlay.saved && !overlay.pendingSave)
        .forEach(([id, overlay]) => {
            const encodedOverlay = encodeOverlay(overlay);
            log.debug('saving overlay to server', overlay, encodedOverlay);
            overlayPendingSave(id);
            setDoc(doc(getFirestore(), key, id), encodedOverlay)
                .then(() => overlaySaved([id, overlay]))
                .catch((e) => {
                    log.error('Error saving overlay: ', e);
                });
            
        });
}
combine([authStore, activeMapStore, overlayStore]).watch(_.debounce(sendToFirebase, 500));

// export function to request overlays given orgId, mapId
export function requestImageOverlays(orgId:string, mapId:string) {
    log.debug('requesting image overlays');
    const key = `organizations/${orgId}/maps/${mapId}/imageOverlays`;
    const unsub = onSnapshot(collection(getFirestore(), key), (snapshot) => {
        // in store, decode annotations and save to store
        snapshot.docChanges().forEach(change => {
            if (change.type === "added") {
                serverEvents.added([change.doc.id, change.doc.data() as EncodedImageOverlay])
            }
            if (change.type === "modified") {
                serverEvents.modified([change.doc.id, change.doc.data() as EncodedImageOverlay])
            }
            if (change.type === "removed") {
                serverEvents.removed([change.doc.id, change.doc.data() as EncodedImageOverlay])
            }
        });
    }, (e) => {
        log.error('Error fetching image overlays: ', e);
    });

    return unsub;
}