import * as React from 'react';
import { useStore } from 'effector-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { clickSignOut } from './Auth/events';
import { AuthData, authStore } from '../lib/auth/store';
// import logo from '../img/mapnotes.png';

function getUserImage(user:AuthData['user']) {
  const firstLetter = user.email?.charAt(0).toUpperCase();
  const style = {
    borderRadius: '9999px',
    backgroundColor: '#ccc',
    width: '28px',
    height: '28px',
    textAlign: 'center',
    lineHeight: '28px',
    fontWeight: 'bold'
  };

  return user.photoURL
    ? <img className="is-rounded" src={user.photoURL} />
    // @ts-ignore - textAlign wants specific type
    : <div style={style}>{firstLetter}</div>
}

export function UserDropdown({user}:{user:AuthData['user']}) {
  return (
    <div className='navbar-item has-dropdown is-hoverable'>
      <a className='navbar-link is-arrowless is-hidden-mobile'>
        <figure className="image is-28x28">
          {getUserImage(user)}
        </figure>
      </a>
      <div className='navbar-dropdown is-right'>
        <Link className='navbar-item' to='/account'>
          My Account
        </Link>
        <a className='navbar-item' href="mailto:support@mapnotes.com" target="_blank" title="Email support">
          Support
        </a>
        <a className='navbar-item' onClick={()=> clickSignOut()}>
          Sign Out
        </a>
      </div>
    </div>
  );
}

interface NavbarProps {
    brandItems: () => JSX.Element | null,
    menuStart?: () => JSX.Element | null,
    menuEnd?: () => JSX.Element | null
}

export default function Navbar(props:NavbarProps) {
  const [menuActive, setMenuActive] = React.useState(false);
  const { brandItems, menuStart, menuEnd } = props;

  function toggleMenu() {
    setMenuActive(!menuActive);
  }

  const menuClasses = classNames(
    'navbar-menu', 
    {
        'is-active': menuActive
    }
  );

  const burgerClasses = classNames(
    'navbar-burger',
    { 
        'is-active': menuActive
    }
  );

  return (<nav className="navbar" role="navigation" aria-label="main navigation">
  <div className='container'>
    <div className="navbar-brand">
      {brandItems? brandItems(): null}

      <a role="button" className={burgerClasses} aria-label="menu" aria-expanded="false" onClick={toggleMenu}>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div className={menuClasses}>
      
        { menuStart
            ? <div className="navbar-start">{menuStart()}</div>
            : null
        }

        { menuEnd
            ? <div className="navbar-end">{menuEnd()}</div>
            : null
        }
    </div>
  </div>
</nav>
)}

export const WithUserDropdown = Component => props => {
    const maybeAuthData = useStore(authStore);
    const { menuEnd, ...otherProps } = props;

    // const 

    return (
      <Component
        menuEnd={() => {
            if (!maybeAuthData.data?.user) {
                return null;
            }

            return (
                <>
                    {menuEnd ? menuEnd() : null}
                    <UserDropdown user={maybeAuthData.data?.user}/>
                </>
            );
        }}
        {...otherProps}
      />
    )
}

export const WithBrand = (Component, linkTo = '/') => props => (
<Component
    brandItems={() => (
    <Link to={linkTo} className='navbar-item'>
        {/* <img src={logo} alt="Mapnotes" /> */}
        Mapnotes
    </Link>
    )}
    {...props}
/>
);