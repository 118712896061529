import { faMapPin, faDrawPolygon, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as React from 'react';
import { useEffect, useState, useRef } from 'react';

function Control({onClick, Icon, type, activeMode, title}) {
    const classes = classNames('button', {
        'is-primary': type === activeMode
    })
    return <p className="control">
        <button className={classes} onClick={onClick} title={title}>
        <span className="icon is-small">
            <Icon />
        </span>
        </button>
    </p>
}

export default function MapControlBox({mapRef, drawRef}) {
    const [activeMode, setActiveMode] = useState<String>();
    const trashRef = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        const listener = (e) => {
            setActiveMode(e.mode)
        }
        mapRef.current.on('draw.modechange', listener);

        return () => {
            mapRef.current.off('draw.modechange', listener);
        };
    }, []);


    enum MapModes {
        POINT = 'draw_point',
        POLYGON = 'draw_polygon',
        LINE = 'draw_line_string',
        SIMPLE_SELECT = 'simple_select'
    };

    function handleClick(mode) {
        // deselect if currently active
        if (activeMode === mode) {
            setActiveMode('');
            drawRef.current.changeMode(MapModes.SIMPLE_SELECT);
            return;
        }
        
        setActiveMode(mode);
        // There's a bug in draw where clicking a control after deselect is not correctly
        // setting that control as the new mode.
        // Reset the mode and then change to the new selected mode.
        drawRef.current.changeMode(MapModes.SIMPLE_SELECT);
        drawRef.current.changeMode(mode);
    }

    function handleTrashClick() {
        drawRef.current.trash();
        trashRef.current?.blur();
    }

    return (
        <div className="field has-addons map-control-box">
            <Control 
                title='Marker tool (m)'
                onClick={() => handleClick(MapModes.POINT)}
                Icon={() => <FontAwesomeIcon icon={faMapPin}/>}
                type={MapModes.POINT}
                activeMode={activeMode}
            />
            <Control 
                title='LineString tool (l)'
                onClick={() => handleClick(MapModes.LINE)}
                Icon={() => 
                    <i className='mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_line' />}
                type={MapModes.LINE}
                activeMode={activeMode}
            />
            <Control 
                title='Polygon tool (p)'
                onClick={() => handleClick(MapModes.POLYGON)}
                Icon={() => <FontAwesomeIcon icon={faDrawPolygon}/>}
                type={MapModes.POLYGON}
                activeMode={activeMode}
            />
            <p className="control">
                <button ref={trashRef} className='button' onClick={handleTrashClick}>
                    <span className="icon is-small">
                        <FontAwesomeIcon icon={faTrash} />
                    </span>
                </button>
            </p>
        </div>
    )
}