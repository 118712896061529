import { User as FirebaseUser } from "@firebase/auth-types";
import { UserInfo } from "firebase/auth";

import { combine, createEvent, createStore, Store } from 'effector';
import * as log from 'loglevel';

import { UserOrgData, UserRole } from '../../../functions/src/organization';
import { authStateChanged, signOutComplete } from './events';
import { clickSignOut } from "../../components/Auth/events";
import { getFunctions, signOut } from "../firebase";
import { userOrgIdReceived } from "./events";
import { httpsCallable } from "@firebase/functions";
import { UserData } from "../../../functions/src/user";
import { Plan } from "../../../functions/src/plan";


const functions = getFunctions();
const getUserData = httpsCallable<{orgId: string}, UserData>(functions, 'getUserData');

const ORG_ITEM_KEY = 'selectedOrgId';

export interface AuthData {
    user: FirebaseUser | UserInfo,
    orgId: string // orgId of the org the user is automatically signed into,
    roles: UserRole[],
    plan: Plan,
    org: UserOrgData
}

interface PendingAuthData {
    user: FirebaseUser | UserInfo,
    orgId?: string // orgId of the org the user is automatically signed into
}

export type MaybeAuthData = AuthData | null;

export interface AuthStore {
    initialized: boolean,
    pending: boolean,
    data: MaybeAuthData
}

export const pendingAuthStore = createStore<PendingAuthData | null>(null);
const initialUserCheckStore = createStore<boolean>(false);

// stores additional data about the user like org and plan data
type MaybeUserData = UserData | null;
const userDataStore = createStore<MaybeUserData>(null);

const userDataReceived = createEvent<UserData>();

// track when we initial check for a user so that we can 
// differentiate between knowing a user doesn't exist
// and not knowing if a user exists or not
initialUserCheckStore.on(authStateChanged, (state) => {
    if (!state) {
        return true;
    }

    return state;
})

// pendingAuthStore collects aggregate auth data
pendingAuthStore.on(authStateChanged, (state, user) => {
    if (!user) {
        return null;
    }

    if (state?.user?.uid === user.uid) {
        return state;
    }

    return {
        user
    };
});

pendingAuthStore.on(userOrgIdReceived, (state, orgId) => {
    if (!state) {
        return state;
    }

    return {
        ...state,
        orgId
    };
});

userOrgIdReceived.watch((orgId) => {
    getUserData({orgId}).then(res => {
        userDataReceived(res.data)
    }).catch(e => {
        log.error('Error getting user roles. ', e);
    });
});

userDataStore.on(userDataReceived, (state, data) => {
    return data;
});

pendingAuthStore.on(clickSignOut, () => {
    return null;
});

userDataStore.on(clickSignOut, () => {
    return null;
})


pendingAuthStore.watch(state => {
    log.debug('pending auth state', state);
    if (!state) {
        return;
    }
    // if state.user && !state.org, check localstorage for orgId
    if (state.user && !state.orgId) {
        const orgIdItem = localStorage.getItem(ORG_ITEM_KEY);
        log.debug('orgiditem', orgIdItem);
        if (orgIdItem) {
            log.debug('orgiditem', orgIdItem);
            // make sure org id matches user
            const [uid, orgId] = orgIdItem.split(':');
            log.debug('compare', uid, orgId, state.user.uid === uid);
            if (state.user.uid === uid) {
                userOrgIdReceived(orgId);
            }
        }
    } else {
        const item = `${state.user.uid}:${state.orgId}`;
        localStorage.setItem(ORG_ITEM_KEY, item);
    }
});

signOutComplete.watch(() => {
    // redirect to main page
    localStorage.removeItem(ORG_ITEM_KEY);
    window.history.pushState({}, '', '/')
});

export const authStore:Store<AuthStore> = combine(initialUserCheckStore, pendingAuthStore, userDataStore, (initialized, pendingAuth, userData) => {
    // return initialized here as part of auth store
    if (pendingAuth?.user && pendingAuth?.orgId && userData) {
        log.debug('authstore data fully available');
        // we have everything we need
        return {
            initialized,
            pending: false,
            data: {
                user: pendingAuth.user,
                orgId: pendingAuth.orgId as string, // TS isn't recognizing `.orgId` must be defined here
                roles: userData.roles,
                plan: userData.plan,
                org: userData.org
            }
        };
    }

    // no user and no org
    if (!pendingAuth?.user && !pendingAuth?.orgId) {
        const store:AuthStore = {
            initialized,
            pending: false,
            data: null
        };
        return store;
    } 

    // user, but still waiting for orgId or roles
    if (pendingAuth.user && !userData) {
        return {
            initialized,
            pending: true,
            data: null
        }
    }

    log.debug('authStore reached unexpected state');

    return {
        initialized: false,
        pending: false,
        data: null
    };
});

authStore.watch(state => {

    log.debug('authstore state', state);
})


authStore.watch(clickSignOut, () => {
    signOut().then(() => {
        signOutComplete();
    });
});