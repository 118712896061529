import { createEvent, createStore } from "effector";
import { collection, getDocs } from "firebase/firestore"; 
import { httpsCallable } from "firebase/functions";
import * as log from 'loglevel';

import { getFirestore, getFunctions } from '../../lib/firebase';
import { MapData } from '../../../functions/src/map';

interface MapsData {
    [id: string]: MapData
}

export interface MapsStore {
    data: MapsData | {},
    pending: boolean
}

interface DeleteMapEvent {
    orgId: string,
    mapId: string
}

interface AddMapResponse {
    data: {
        id: string
    }
}

export const setActiveMap = createEvent<MapData>();
export const addMap = createEvent<string>();
export const addMapError = createEvent<string>();
export const deleteMap = createEvent<DeleteMapEvent>();
export const mapAdded = createEvent<MapData['id']>();
export const selectMap = createEvent<string>();
export const fetchMaps = createEvent<string>();
export const receiveMaps = createEvent<MapsData>();

export const mapsStore = createStore<MapsStore>({data: {}, pending: false});

const functions = getFunctions();
const addMapFn = httpsCallable(functions, 'addMap');
const deleteMapFn = httpsCallable(functions, 'deleteMap');

fetchMaps.watch((organizationId) => {
    // todo - add error checking here
    getDocs(collection(getFirestore(), 'organizations', organizationId, 'maps')).then(snapshot => {
        const docs = snapshot.docs.reduce((accum, doc) => {
            accum[doc.id] = doc.data();
            return accum;
        }, {});
        receiveMaps(docs as MapsData);
    }).catch(e => {
        log.error('Error fetching maps: ', e);
    });
});

mapsStore.on(fetchMaps, (state, event) => {
    return {
        pending: true,
        data: {}
    }
});

mapsStore.on(receiveMaps, (state, maps) => {
    return {
        ...state,
        pending: false,
        data: maps
    };
});

mapsStore.on(deleteMap, (state, { mapId }) => {
    if (!Object.keys(state).length) {
        return;
    }
    const { [mapId]: removedEvent, ...newData} = state.data as MapsData;

    return {
        ...state,
        data: newData
    };
});

addMap.watch(orgId => {
    // @ts-ignore
    addMapFn({orgId}).then((result:AddMapResponse) => {
        mapAdded(result.data.id)
    }).catch(e => {
        // TODO handle map limit reached error
        addMapError(e.message)
        console.error('Error adding new map: ', e);
    });
});

deleteMap.watch(({orgId, mapId}) => {
    deleteMapFn({orgId, mapId})
        .catch(e => {
            console.error('Error deleting map: ', e);
        });
})
