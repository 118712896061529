// this file is separate so it can be included inour functions file

import {GeoJsonTypes, Geometry} from 'geojson';

export enum TextAnchorPosition {
    'top-left' = 'top-left',
    'top' = 'top',
    'top-right' = 'top-right',
    'left' = 'left',
    'center' = 'center',
    'right' = 'right',
    'bottom-left' = 'bottom-left',
    'bottom' = 'bottom',
    'bottom-right' = 'bottom-right'
}

export interface MapAnnotationProperties {
    name?: string,
    color?: string,
    textColor?: string,
    textHaloColor?: string,
    textAnchor?: TextAnchorPosition,
    images?: {
        [filePath:string]: {
            status: 'available',
            height: number,
            width: number
        }
    }
}

export interface MapAnnotation {
    id: string,
    type: GeoJsonTypes,
    properties: MapAnnotationProperties // also may include other geojson properties
    geometry: Geometry,
    lastModified: number,
    visible?: boolean,
    saved?: boolean
    pendingSave?: boolean
}

export type MaybeMapAnnotation = null | MapAnnotation;

export interface EncodedAnnotation {
    id: string,
    type: GeoJsonTypes,
    properties: string,
    geometry: string,
    lastModified: number
}

export type MaybeEncodedAnnotation = null | EncodedAnnotation;

export function encodeAnnotation(annotation:MapAnnotation):EncodedAnnotation {
    const {
        id,
        type,
        properties,
        geometry,
        lastModified
    } = annotation;

    return {
        id,
        type,
        properties: JSON.stringify(properties),
        geometry: JSON.stringify(geometry),
        lastModified
    };
}

export function decodeAnnotation(encodedAnnotation:EncodedAnnotation):MapAnnotation {
    const {
        id,
        type,
        properties,
        geometry,
        lastModified
    } = encodedAnnotation;

    return {
        id,
        type,
        properties: JSON.parse(properties),
        geometry: JSON.parse(geometry),
        lastModified
    };
}
