import { useStore } from 'effector-react';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';


import { isAdmin, WithAuth } from '../../components/Auth';

import { MapData } from '../../../functions/src/map';
import { addMap, addMapError, fetchMaps, mapAdded, MapsStore, mapsStore, deleteMap } from './store';
import { faSpinner, faTrash, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullScreenLoader } from '../../components/Loader';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { UserOrgData } from '../../../functions/src/organization';
import { isNewMapAllowed } from '../../../functions/src/lib.shared';
import { AuthData } from '../../lib/auth/store';
import { Plan } from '../../../functions/src/plan';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';

interface ListProps {
    maps: MapsStore['data']
    orgId: string
}

const handleDelete = (orgId, mapId) => {
    const conf = window.confirm('Are you sure?');
    return conf && deleteMap({orgId, mapId});
}


function MapCard({map, id, orgId, auth}: {map: MapData, id:string, orgId: string, auth: AuthData}) {

    const mapLink = isAdmin(auth)
        ? <Link to={`/map/${id}/edit`} title="edit" className='has-text-dark'>
            <span className='icon-text'>
                <span className='is-hidden-mobile is-hidden-tablet-only'>Edit</span>
                <span className='icon'>
                    <FontAwesomeIcon icon={faPenToSquare} />
                </span>
            </span>
        </Link>
        : <Link to={`/map/${id}/view`} title="view">
            <span className='icon-text'>
                <span className='is-hidden-mobile is-hidden-tablet-only'>View</span>
                <span className='icon'>
                    <FontAwesomeIcon icon={faUpRightFromSquare} />
                </span>
            </span>
        </Link>

    return (
        <div className="card">
            <div className="card-header is-primary">
                <div className="card-header-title">{map.name}</div>
            </div>
            <div className="card-image">
                <figure className="image is-4by3">
                    {map.imgData && <img src={map.imgData} alt="Map image"/> }
                </figure>
            </div>
            <div className="card-footer">
                <div className="card-footer-item">
                    {mapLink}
                </div>
                <div className="card-footer-item">
                    <a className='' onClick={() => handleDelete(orgId, id)}>
                        <span className='icon-text'>
                            <span className='is-hidden-mobile is-hidden-tablet-only'>Delete</span>
                            <span className='icon'>
                                <FontAwesomeIcon icon={faTrash} />
                            </span>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    );
}

const MapCardWithAuth = WithAuth(MapCard);

function List({ maps, orgId }: ListProps) {
    
    // allows gaps on right and left of screen to be visible
    // this looks better with cards
    const style = {
        marginLeft: 'auto',
        marginRight: 'auto'
    };

    return <div className="columns is-vcentered is-multiline is-mobile">
        {Object.entries(maps).map(([id, map]) => {
            return (
                <div className="column is-6-mobile is-4-tablet is-3-desktop" key={id}>
                    <MapCardWithAuth id={id} map={map} orgId={orgId}/>
                </div>
            );
        })}
    </div>
}

interface MenuBarProps {
    maps: MapsStore['data']
    orgId: string
    org: UserOrgData
    plan: Plan
}


const UpgradeNotice = () => (
        <span className="has-text-primary mr-3"><Link to="/account/billing">Upgrade</Link> to add more maps.</span>
)

const MenuBar = ({ maps, orgId, org, plan }: MenuBarProps) => {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    const isAllowed = isNewMapAllowed({...plan.restrictions, ...org.restrictions}, Object.keys(maps).length);

    const disabled = loading || !isAllowed;

    function handleNew() {
        setError('');
        setLoading(true)
        addMap(orgId)
    };
    
    React.useEffect(() => {
        return addMapError.watch(e => {
            setLoading(false);
            setError(e);
        });
    }, []);

    return  <div className="columns">
        <div className='column is-8 is-offset-4 has-text-right'>
            { disabled && !loading && <UpgradeNotice /> }
            { error && <p className="has-text-danger">{error}</p> }
            <NewMap loading={loading} onClick={handleNew} disabled={disabled} />
        </div>
    </div>
            
    
}

const NewMap = ({loading, onClick, disabled}) => {

    return <button
        className="button is-primary is-outlined"
        onClick={onClick}
        disabled={disabled}
    >
        { loading && <span className='icon'>
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" /> 
            </span>
        } 
        <span>New</span>  
    </button>
}

function Maps({auth}:{auth:AuthData}) {
    // TODO cache map load for a few seconds so we don't reload them if we click "back" immediately
    const maps = useStore(mapsStore);
    const navigate = useNavigate();
    // onMount load maps
    React.useEffect(() => {
        fetchMaps(auth.orgId);
    }, [])

    React.useEffect(() => {
        const unsub = mapAdded.watch(id => {
            navigate(`/map/${id}/edit`);
        });
        
        return unsub;
    }, []);

    if (maps.pending) {
        return <FullScreenLoader message="Loading your maps" />;
    }

    const classes = classNames('container mt-3 mb-3 page-body', {
        'mr-3': isMobile,
        'ml-3': isMobile
    });

     return (
        <div className={classes}>
            <MenuBar maps={maps.data} orgId={auth.orgId} org={auth.org} plan={auth.plan} />
            <List maps={maps.data} orgId={auth.orgId}></List>
        </div>
     );
}

export default function MapsPage(props) {
    return WithAuth(Maps)(props);
}