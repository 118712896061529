
export function getNextVertex(currentIndex:number, arrayLength:number) {
    return (currentIndex + 1) % arrayLength;
}

export function getPreviousIndex(currentIndex:number, arrayLength:number) {
   return  (currentIndex + arrayLength - 1) % arrayLength;
}

export function getSlopeOfLine([x1,y1]:number[], [x2,y2]:number[], reverse:boolean = false) {
    const slope = (y2-y1)/(x2-x1);
    return reverse ? -1 * slope : slope;
}

function infinityUnsigned(a:number) {
    return (a === Infinity || a === -Infinity);
}

// https://stackoverflow.com/questions/13937782/calculating-the-point-of-intersection-of-two-lines
// https://www.mathsisfun.com/algebra/line-equation-point-slope.html
export function getIntersection([x1,y1]:number[], m1:number, [x2,y2]:number[], m2:number) {
    // const y1 = m1 * x1 + b1;
    // const y2 = m2 * x2 + b2;

    // m2(x − x2) = m1(x − x1)
    // x − x2 = m1(x − x1) / m2
    // x - x2 = ((m1 * x) - (m1 * x1)) / m2
    // x = ((m1 * x) - (m1 * x1)) / m2    + x2

    // m2(x − x2) = m1(x − x1)
    // m2*x - m2*x2 = m1*x - m1*x1
    // m2*x = m1*x - m1*x1 + m2*x2
    // m2*x - m1*x = -1*m1*x1 + m2*x2
    // x(m2 - m1) = -1*m1*x1 + m2*x2
    // xint = -1*m1*x1 + m2*x2 / (m2 - m1)


    // y = mx + b

    const b1 = y1 - x1*m1;
    const b2 = y2 - x2*m2;

    const newX = infinityUnsigned(m1)
        ? x1
        : m1 === 0 
            ? x2 
            : (b2-b1) / (m1-m2);

    const newY = infinityUnsigned(m2)
        ? y1
        : m2 === 0
            ? y2
            : m2*newX + b2;


    return [newX, newY];
}


export function getAngleFromSlope(rise:number, run:number) {
    return Math.atan(rise/run) * 180 / Math.PI;
}




// takes a poly and a vertex and generates a new polygon
// with other vertices aligned to new vertex by right angles.
// Imagine moving one corner of a rectangle and expecting the 
// rectangle to adjust shape accordingly
export function buildPolyFromMove(currentPolygon:number[][], indexToManipulate:number, vertexPoints: number[]) {
    // find interesection of new ab & cd
    const len = currentPolygon.length;
    const nextVertexIndex = getNextVertex(indexToManipulate, len)
    const previousVertexIndex = getPreviousIndex(indexToManipulate, len);
    const [vX, vY] = vertexPoints;

    //a,b
    //d,c
    // a is moved so find new b and d
    // find slope of bc 
    const newA = vertexPoints;
    const a = currentPolygon[indexToManipulate];
    const b = currentPolygon[nextVertexIndex];
    const oppositeVertexIndex = getNextVertex(nextVertexIndex, len);
    const c = currentPolygon[oppositeVertexIndex];
    const d = currentPolygon[getNextVertex(oppositeVertexIndex, len)];
    const slopeCB = getSlopeOfLine(c,b);
    // find slope of ab
    const slopeAB = getSlopeOfLine(a, b);
    // find intersection of new ab & bc
    const newB = getIntersection(newA, slopeAB, c, slopeCB);
    // find intersection of new ad & dc
    const slopeAD = getSlopeOfLine(a, d);
    const slopeCD = getSlopeOfLine(c, d);
    const newD = getIntersection(newA, slopeAD, c, slopeCD);
    const newPoints:number[][] = [[], [], [], []];
    newPoints[indexToManipulate] = newA;
    newPoints[nextVertexIndex] = newB;
    newPoints[oppositeVertexIndex] = c;
    newPoints[previousVertexIndex] = newD;

    return newPoints;
}