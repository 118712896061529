import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';

interface PanelTab {
    title: () => React.ReactNode,
    content: () => React.ReactNode
}

export interface PanelProps {
    collapsible: boolean,
    panelHeading: () => React.ReactNode
    panelTabs?: PanelTab[]
    children?:  React.ReactNode | React.ReactNode[]
}

export default function Panel(props: PanelProps) {
    const [activeTab, setActiveTab] = useState(0);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const {panelHeading, panelTabs = [], collapsible, children} = props;
    const Collapse = () => {
        return <a className="is-white icon-text" onClick={() => setIsCollapsed(!isCollapsed)}>
            <span className="icon">
                <FontAwesomeIcon icon={isCollapsed ? faCaretRight : faCaretDown} />
            </span>
            <span>{panelHeading()}</span>
        </a> 
    };

    const containerClasses = classNames('panel-child-container', {
        'is-collapsed': isCollapsed
    });

    return (
        <div
            className="panel"
        >
            <p className="panel-heading">
                {collapsible ? <Collapse />: panelHeading()}
            </p>
            {panelTabs.length
                ? <p className="panel-tabs">
                    {panelTabs.map((tab, index) => {
                        const className = classNames({
                            'is-active': activeTab === index
                        })
                        return <a className={className} onClick={() => setActiveTab(index)} key={index}>{tab.title()} </a>
                    })}
                </p>
                : null
            }
            {panelTabs.length ? panelTabs[activeTab].content() : null}
            <div className={containerClasses}>
                {children}
            </div>
        </div>
    );
}