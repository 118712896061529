import { createEvent } from 'effector';
import { getDoc, doc, getFirestore } from 'firebase/firestore';
import { auth } from 'firebaseui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as log from 'loglevel';

import { Organization } from '../../../functions/src/organization';
import { User } from '../../../functions/src/user';

import { FullScreenLoader } from '../Loader';
import { AuthData } from '../../lib/auth/store';

interface ChangeOrgProps {
    user: AuthData['user'],
    onClick: (orgId: string) => any
}

interface OrgStateStore {
    [orgId:string]: Organization
}

function zip(a:string[], b:any[]) {
    return a.map((val, index) => {
        return [val, b[index]];
    });
}

function OrgLink(props:{orgId: string, org:Organization, onClick: (orgId:string) => any}) {
    const { org, orgId, onClick } = props;
    const name = org.name || 'unnamed';
    return (
        <li><a title={`select ${name}`} onClick={() => onClick(orgId)}>{`${name} (${orgId})`}</a></li>
    )
}

function ChangeOrg(props:ChangeOrgProps) {
    const { user, onClick } = props;
    const [orgs, setOrgs] = useState<OrgStateStore>({});

    useEffect(() => {
        const key = `users/${user.uid}`;
        // get all orgs for user
        getDoc(doc(getFirestore(), key))
            .then(snapshot => snapshot.data() as User)
            .then(data => {
                if (!data.orgIds) {
                    throw new Error('User org does not exist');
                }

                return Object.keys(data.orgIds);
            })
            .then((orgIds): Promise<[string[], Organization[]]> => {
                const promises = orgIds.map(orgId =>
                    getDoc(doc(getFirestore(), `/organizations/${orgId}`))
                        .then(snapshot => snapshot.data() as Organization)
                );
                return Promise.all([orgIds, Promise.all(promises)]);
            })
            .then(([orgIds, promises]) => {
                return Object.fromEntries(zip(orgIds, promises))
            }).then(setOrgs)
            .catch(e => {
                log.error('Error getting org data.', e);
            });;
        
    }, []);
    
    if (!Object.keys(orgs).length) {
        return <FullScreenLoader message='Getting organization data' />
    }
    return (
        <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-content">
                <div className='box'>
                    Which organization would you like to sign into?
                    <ul>
                    {Object.entries(orgs).map(([orgId, org]) => (
                        <OrgLink key={orgId} org={org} orgId={orgId} onClick={onClick} />
                    ))}
                    </ul>
                </div>
            </div>
            <button className="modal-close is-large" aria-label="close"></button>
        </div>
    )
}

export default ChangeOrg;