import classNames from 'classnames';
import * as React from 'react';

export default function Footer({fixed = false}:{fixed?:boolean}) {
    const classes = classNames('footer', {
        'fixed-footer': fixed
    });
    
    return (
        <footer className={classes}>
            <div className="content has-text-centered">
                <p>
                © 2022 All Rights Reserved, Mapnotes LLC
                </p>
            </div>
        </footer>
    )
}

