
import * as React from 'react';
import { useRef, useEffect } from 'react';

const WithOffClick = (Component) => props =>{
    const { offClick, ...otherProps } = props;
    const ref = useRef(null);
    useEffect(() => {
        function handleMouseDown(event) {
            // @ts-ignore
            if (ref.current && !ref.current.contains(event.target)) {
                offClick(event);
            }
        }
        document.addEventListener('mousedown', handleMouseDown);

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
        }
    }, [ref]);

    return <Component inputRef={ref} {...otherProps} />
}

export default WithOffClick;