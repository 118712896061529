import { createStore, createEvent, combine } from 'effector';
import { doc, setDoc, deleteDoc, getDoc, updateDoc, collection, onSnapshot } from "firebase/firestore"; 
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import log from '../../lib/log';
import { AuthStore, authStore } from '../../lib/auth/store';
import { getFirestore } from '../../lib/firebase';
import { MapAnnotation, EncodedAnnotation, MaybeEncodedAnnotation, decodeAnnotation, encodeAnnotation } from '../../../functions/src/annotations';
import { MapData } from '../../../functions/src/map';

import { DrawEvent } from '../../components/Map';
import { pasteRequested } from './events';

export interface ActiveMapStore {
    initialLoad: boolean,
    pending: boolean,
    data: MaybeMap
}

export type MaybeMap = null | MapData;

export interface MapAnnotationStore{
    [id:string]: MapAnnotation
}

interface MapDrawEvent {
    orgId: string,
    mapId: string,
    event: DrawEvent
}

interface MapCenter {
    center: MapData['center'],
    zoom: MapData['zoom']
}

export const fetchMap = createEvent<{orgId: string, mapId: string}>();
export const receiveMap = createEvent<MapData>();
export const setMapCenter = createEvent<MapCenter>();
export const mapNameModified = createEvent<{id: string, name: string | undefined}>();
export const mapImageCaptured = createEvent<{id: string, img: string}>();
export const unmount = createEvent<string>();

// mapdrawcreate & update is triggered when user draws something
export const mapDrawCreate = createEvent<MapDrawEvent>();
export const mapDrawUpdate = createEvent<MapDrawEvent>();
// programmatic update is for when we manually tell map to draw
export const mapDrawProgrammaticUpdate = createEvent<MapAnnotation>();
export const mapDrawDelete = createEvent<MapDrawEvent>();
export const localAnnotationPropertiesModified = createEvent<{id: MapAnnotation['id'], properties: MapAnnotation['properties'], modified: number}>();
export const serverAnnotationAdded = createEvent<EncodedAnnotation>();
export const serverAnnotationRemoved = createEvent<EncodedAnnotation>();
export const serverAnnotationModified = createEvent<EncodedAnnotation>();
export const pinDropRequested = createEvent<[number, number]>();
export const resetStore = createEvent();

export const activeMapStore = createStore<ActiveMapStore>({
    initialLoad: false,
    data: null,
    pending: false
});

export const mapAnnotationsStore = createStore<MapAnnotationStore>({}).reset(resetStore);
export const serverDeletedAnnotationStore = createStore<MaybeEncodedAnnotation>(null);
export const pendingPasteStore = createStore<{[id:MapAnnotation['id']]: boolean}>({});
export const annotationPasteComplete = createEvent<{[id:MapAnnotation['id']]: true}>(); // string of ids
const pastedItemsCreated = createEvent<MapAnnotation['id'][]>();
const annotationSaved = createEvent<MapAnnotation>();
const annotationPendingSave = createEvent<MapAnnotation>();

function transformDrawEventToAnnotation(drawEvent:MapDrawEvent):MapAnnotationStore {
    const changes = drawEvent.event.features.reduce((accum,f) => {
        const change:MapAnnotation = {
            ...f,
            visible: true,
            saved: false,
            lastModified: Date.now()
        }
        return accum.set(f.id, change);
    }, new Map);

    return Object.fromEntries(changes);
}

// map the raw events here to an annotation store event
const mapAnnotationsAddedFromDrawEvent = mapDrawCreate.map(transformDrawEventToAnnotation);
const mapAnnotationsUpdatedFromDrawEvent = mapDrawUpdate.map(transformDrawEventToAnnotation);
const mapAnnotationDeletedFromDrawEvent = mapDrawDelete.map(transformDrawEventToAnnotation);
export const mapAnnotationAddedFromServer = serverAnnotationAdded.map(decodeAnnotation);
export const mapAnnotationUpdatedFromServer = serverAnnotationModified.map(decodeAnnotation);

// update local store
mapAnnotationsStore.on([mapAnnotationsAddedFromDrawEvent, mapAnnotationsUpdatedFromDrawEvent], (state, annotations) => {
    log.debug('mapAnnotationsStore:mapAnnotationsAddedFromDrawEvent', annotations);
    return {...state, ...annotations};
});

// remove annotation from local store
mapAnnotationsStore.on(mapAnnotationDeletedFromDrawEvent, (state, annotationsToDelete) => {
    log.debug('mapAnnotationsStore:mapAnnotationDeletedFromDrawEvent', annotationsToDelete);
    const newState = Object.entries(state).reduce((acc, [id, annotation]) => {
        // omit from new state if annotation is in delete group
        if (annotationsToDelete[id]) {
            return acc;
        }

        acc[id] = annotation;
        return acc;
    }, {});

    return newState;
});

// acknowledge we've drawn this and set visible to true
mapAnnotationsStore.on([mapDrawProgrammaticUpdate], (state, annotation) => {
    log.debug('mapAnnotationsStore:mapDrawProgrammaticUpdate', annotation);
    const cleanAnnotation = {
        ...annotation,
        visible: true
    };
    return {...state, [cleanAnnotation.id]: cleanAnnotation};
});

// acknowledge we've saved this to the server
mapAnnotationsStore.on(annotationSaved, (state, annotation) => {
    // if local annotation is newer than server, ignore
    const localAnnotation = state[annotation.id];
    const localLastMod = localAnnotation?.lastModified || 0;
    const oldUpdate = annotation.lastModified <= localLastMod;

    if (oldUpdate) {
        return state;
    }

    const updatedAnnotation:MapAnnotation = {
        ...annotation,
        pendingSave: false,
        saved: true
    };
    log.debug('mapAnnotationsStore:annotationSaved', updatedAnnotation);

    return {...state, [annotation.id]: updatedAnnotation};
});

// acknowledge we've sent a request to save to the server
mapAnnotationsStore.on(annotationPendingSave, (state, annotation) => {
    // if local annotation is newer than server, ignore
    const localAnnotation = state[annotation.id];
    const localLastMod = localAnnotation?.lastModified || 0;
    const oldUpdate = annotation.lastModified <= localLastMod;

    if (oldUpdate) {
        return state;
    }

    const updatedAnnotation:MapAnnotation = {
        ...annotation,
        pendingSave: true
    };
    log.debug('mapAnnotationsStore:annotationPendingSave', updatedAnnotation);

    return {...state, [annotation.id]: updatedAnnotation};
});

// makes sure we draw incoming server changes
mapAnnotationsStore.on([mapAnnotationAddedFromServer, mapAnnotationUpdatedFromServer], (state, annotation) => {
    // if local annotation is newer than server, ignore
    const localAnnotation = state[annotation.id];
    const localLastMod = localAnnotation?.lastModified || 0;
    const oldUpdate = annotation.lastModified <= localLastMod;

    log.debug('mapAnnotationsStore:mapAnnotationUpdatedFromServer:oldUpdate', oldUpdate)
    if (oldUpdate) {
        return state;
    }
    
    const dirtyAnnotation:MapAnnotation = {
        ...annotation,
        visible: false,
        saved: true
    }

    return {...state, [annotation.id]: dirtyAnnotation};
});

mapAnnotationsStore.on(serverAnnotationRemoved, (state, encodedAnnotation) => {
    log.debug('mapAnnotationsStore:serverAnnotationRemoved', encodedAnnotation)
    const {[encodedAnnotation.id]:removedAnnotation, ...newState} = state;

    return newState;
});

// when properties are updated via the menu, draw them and save them
mapAnnotationsStore.on(localAnnotationPropertiesModified, (state, {id, properties, modified}) => {
    const currentAnnotation = state[id];
    log.debug('mapAnnotationsStore:localAnnotationPropertiesModified', currentAnnotation);
    return {...state, [id]: {
        ...currentAnnotation,
        properties,
        saved: false,
        visible: false,
        lastModified: modified
    }};
});

mapAnnotationsStore.on(pasteRequested, (state, annotations) => {
    // remove the id from each annotation, then save to the store and request a draw and a save
    const raw = annotations.reduce((lookup, annotation) => {
        // overwrite id
        const id = uuidv4();
        lookup[id] = {
            ...annotation,
            id,
            saved: false,
            visible: false,
            lastModified: Date.now()
        };

        return lookup;
    }, {});
    log.debug('mapAnnotationsStore:pasteRequested', raw);

    pastedItemsCreated(Object.keys(raw));

    return {
        ...state,
        ...raw
    };
});

pendingPasteStore.on(pastedItemsCreated, (state, ids) => {
    return ids.reduce((lookup, id) => {
        lookup[id] = false;
        return lookup;
    }, {})
});

// when the annotation is drawn, mark it as true from the clipboard
pendingPasteStore.on(mapDrawProgrammaticUpdate, (state, annotation) => {
    // if update isn't in the pending paste store, ignore
    if (!state[annotation.id]) {
        return state;
    }

    return {
        ...state,
        [annotation.id]: true
    };
});

pendingPasteStore.watch(state => {
    // when everything in state is pasted, select feature
    // @ts-ignore - ts complains about the following line, but it works correctly
    if(!Object.values(state).indexOf(false) > -1) {
        annotationPasteComplete(state as {
            [id: string]: true;
        });
    }
});

// mapAnnotationsStore.on(pinDropRequested, (state, position) => {
//     const pt = point(position);
//     const id = uuidv4();
//     pt.id = id;
//     return {...state, [id]: pt};
// });

serverDeletedAnnotationStore.on(serverAnnotationRemoved, (state, encodeAnnotation) => {
    return encodeAnnotation;
});

// load doc when map is fetched
fetchMap.watch(({orgId, mapId}) => {
    getDoc(doc(getFirestore(), 'organizations', orgId, 'maps', mapId)).then(snapshot => {
        const map = {id:mapId, ...snapshot.data()};
        receiveMap(map as MapData)
    }).catch((e) => {
        console.error('Error fetching map: ', e)
    });
});


// fetch map is triggered on the Map Page
activeMapStore.on(fetchMap, (state, event) => {
    log.debug('activeMapStore:fetchMap', event);
    return {
        initialLoad: true,
        pending: true,
        data: null
    };
});

activeMapStore.on(receiveMap, (state, activeMap) => {
    log.debug('activeMapStore:receiveMap', event);
    return {
        initialLoad: true,
        pending: false,
        data: activeMap
    }
});

activeMapStore.on(setMapCenter, (mapStore, {center, zoom}) => {
    log.debug('activeMapStore:setMapCenter', event);
    if(!mapStore.data) {
        return;
    }

    return {
        ...mapStore,
        initialLoad: false,
        data: {
            ...mapStore.data,
            center,
            zoom
        }
    };
});

activeMapStore.on(mapNameModified, (mapStore, {name}) => {
    log.debug('activeMapStore:mapNameModified', event);
    if (!mapStore.data) {
        return;
    }
    return {
        ...mapStore,
        initialLoad: false,
        data: {
            ...mapStore.data,
            name
        }
    };
});

activeMapStore.on(mapImageCaptured, (mapStore, {img}) => {
    log.debug('activeMapStore:mapImageCaptured', event);
    if (!mapStore.data) {
        return;
    }
    return {
        ...mapStore,
        initialLoad: false,
        data: {
            ...mapStore.data,
            imgData: img
        }
    };
});


const authActiveMapStore = combine([authStore, activeMapStore]);

// watch for map data updates
authActiveMapStore.watch(([authStore, activeMapStore]) => {
    if (!authStore.data || !activeMapStore.data || activeMapStore.initialLoad) {
        return;
    }
    const key = `organizations/${authStore.data.orgId}/maps/${activeMapStore.data.id}`;
    updateDoc(doc(getFirestore(), key), activeMapStore.data as any).catch(e => {
        log.error('Error updating map: ', e);
    }).then(() => {
        log.debug('Map updated.');
    });
});

// save annotations 
type SendToFirebaseParams = [AuthStore, ActiveMapStore, MapAnnotationStore]
const sendToFirebase = ([maybeAuth, activeMapStore, mapAnnotationsStore]:SendToFirebaseParams) => {
    if (!maybeAuth.data || !activeMapStore.data?.id) {
        return
    }
    const key = `organizations/${maybeAuth.data.orgId}/maps/${activeMapStore.data.id}/annotations`
    // for every annotation update the doc
    Object.values(mapAnnotationsStore)
        .filter(annotation => !annotation.saved && !annotation.pendingSave)
        .forEach(annotation => {
            const encodedAnnotation = encodeAnnotation(annotation);
            log.debug('saving annotation to server', annotation, encodedAnnotation);
            annotationPendingSave(annotation);
            setDoc(doc(getFirestore(), key, annotation.id), encodedAnnotation)
                .then(() => annotationSaved(annotation))
                .catch((e) => {
                    log.error('Error saving annotations: ', e);
                });
            
        });
}
combine([authStore, activeMapStore, mapAnnotationsStore]).watch(_.debounce(sendToFirebase, 500));

// delete annotations
mapDrawDelete.watch(({orgId, mapId, event}) => {
    const annotations = event.features;
    if (!annotations.length) {
        return
    }
    const key = `organizations/${orgId}/maps/${mapId}/annotations`;

    annotations.forEach(annotation => {
        deleteDoc(doc(getFirestore(), key, annotation.id)).catch(e => {
            log.error('Error deleting annotations: ', e)
        });
    });
});

export function init(orgId, mapId) {
        // TODO deleting all annotations from server will not successfully delete annotations
        // if map is open
        const key = `organizations/${orgId}/maps/${mapId}/annotations`
        const unsub = onSnapshot(collection(getFirestore(), key), (snapshot) => {
            // map data, trigger annotationsreceived event
            // in store, decode annotations and save to store
            snapshot.docChanges().forEach(change => {
                if (change.type === "added") {
                    serverAnnotationAdded(change.doc.data() as EncodedAnnotation)
                }
                if (change.type === "modified") {
                    serverAnnotationModified(change.doc.data() as EncodedAnnotation)
                }
                if (change.type === "removed") {
                    serverAnnotationRemoved(change.doc.data() as EncodedAnnotation)
                }
            });
        }, (e) => {
            log.error('Error fetching map snapshot: ', e);
        });

        return unsub;
}