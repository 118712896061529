import { ref } from '@firebase/storage';
import { getDownloadURL } from 'firebase/storage';
import mapboxgl from 'mapbox-gl';
import { MutableRefObject, useEffect } from 'react';
import createCircle from '@turf/circle';
import createBbox from '@turf/bbox';
import createBboxPolygon from '@turf/bbox-polygon';
import pointWithinPolygon from '@turf/boolean-point-in-polygon';
import { featureCollection, lineString, point, polygon } from '@turf/helpers';
import { getCoords } from '@turf/invariant';
import turfDestination from '@turf/destination';
import getNearestPoint from '@turf/nearest-point';
import explode from '@turf/explode';
import transformRotate from '@turf/transform-rotate';

import { getStorage } from '../../lib/firebase';
import log from '../../lib/log';
import { ImageOverlay as ImageOverlayType } from '../../../functions/src/imageOverlay';

// @ts-ignore
import rotateSVG from '../../img/rotate-option-svgrepo-com.svg';
// @ts-ignore
import stretchSVG from '../../img/diagonal-resize-svgrepo-com.svg';
// @ts-ignore
import dragSVG from '../../img/drag-svgrepo-com.svg';
import { createEvent, createStore } from 'effector';
import { Feature, FeatureCollection, LineString, Point } from 'geojson';
import { buildPolyFromMove, getAngleFromSlope } from './util';
const RASTER_OPACITY_DEFAULT = 1;
const RASTER_OPACITY_ACTIVE = .7;

// future image manipulation libs to consider to align drone image with satelite image
// https://github.com/jimp-dev/jimp#image-manipulation-methods-default-plugins
// https://github.com/nodeca/pica

function getDistance([x1, y1]:number[], [x2, y2]:number[]){
    const y = x2 - x1;
    const x = y2 - y1;
    
    return Math.sqrt(x * x + y * y);
}

function getAngle(p1, p2) {
    const [p1x, p1y] = p1;
    const [p2x, p2y] = p2;
    return Math.atan2(p2y - p1y, p2x - p1x) * 180 / Math.PI;
}

function findNewPoint([x, y], angle, distance) {
    var result = {
        x: Math.cos(angle * Math.PI / 180) * distance + x,
        y: Math.sin(angle * Math.PI / 180) * distance + y
    };

    return [result.x, result.y];
}

export interface ImageOverlayProps {
    id: string,
    mapRef: MutableRefObject<mapboxgl.Map>,
    draw: MutableRefObject<MapboxDraw>,
    bucketUrl: ImageOverlayType['bucketUrl'],
    center: ImageOverlayType['center'],
    imageHeight: ImageOverlayType['imageHeight'],
    imageWidth: ImageOverlayType['imageWidth'],
    bboxPoly?: ImageOverlayType['bboxPoly'],
    viewOnly?: boolean
}

interface ImageStore {
    id: string,
    active: boolean,
    diameter: number, // distance from corner to corner,
    center: ImageOverlayType['center'],
    bboxPoly: ImageOverlayType['bboxPoly']
    rotationCircle: FeatureCollection<Point>
}

function getRotationCircle({center, bboxPoly}:{center:ImageStore['center'], bboxPoly:ImageStore['bboxPoly']}) {
    const polyCoords = getCoords(bboxPoly)[0];
    const diameter = getDistance(polyCoords[0], center);
    const rotationCircle = createCircle(center, diameter, {
        steps: 256
    });

    return {diameter, rotationCircle: explode(rotationCircle)};
}

export const imageOverlayDrawComplete = createEvent<ImageStore>()

const getDraw = (map, url) => (image:ImageStore) => {
    const source = map.current.getSource('box-source') as mapboxgl.GeoJSONSource;
    const imgSource = map.current.getSource('image-source') as mapboxgl.ImageSource;
    const polyCoords = image.bboxPoly.geometry.coordinates[0].slice(0, 4);
    const imgSourceCoords = [polyCoords[1], polyCoords[0], polyCoords[3], polyCoords[2]];

    if (!imgSource) {
        map.current.addSource('image-source', {
            'type': 'image',
            url,
            'coordinates': imgSourceCoords
        });
        const layers = map.current.getStyle().layers;
        // Find the index of the first symbol layer in the map style.
        // const firstSymbolId = layers.find(layer => layer.id === 'symbol');

        // add layer before our symbols
        map.current.addLayer({
            id: 'raster-layer',
            'type': 'raster',
            'source': 'image-source',
            'paint': {
                'raster-fade-duration': 0,
                'raster-opacity': RASTER_OPACITY_DEFAULT
            }
        }, layers[2].id);
    } else {
        imgSource.setCoordinates(imgSourceCoords);
    }

    if (!source) {
        map.current.addSource('box-source', {
            type: 'geojson',
            data: image.bboxPoly,
        })

        map.current.addLayer({
            'id': 'test-outline',
            'type': 'line',
            'source': 'box-source',
            'layout': {
            'line-cap': 'round',
            'line-join': 'round',
            'visibility': 'none'
            },
            'paint': {
            'line-color': '#fbb03b',// orange
            'line-dasharray': [0.2, 2],
            'line-width': 2
            }
        });
    } else {
        source.setData(image.bboxPoly);
    }

    log.debug('draw complete');
}


export function getInitialBboxPoly(center, height, width) {
    const ratio = height/width;
    const mapWidth = .5; // kilometers
    const mapHeight = ratio*mapWidth;
    const hypotenuse = Math.sqrt((mapHeight) * (mapHeight) * (mapWidth) * (mapWidth));
    // bearing uses E as 0
    const angleOfHypotenuse = getAngleFromSlope(mapHeight, -mapWidth);
    // shift to bottom left
    const bearingBottomLeft = 180 + angleOfHypotenuse - 90; // -90 is to offset N bearing for turf
    const bearingTopRight = angleOfHypotenuse -90;
    // turf destination uses N as bearing 0
    const bottomLeft = turfDestination(center, hypotenuse / 2, bearingBottomLeft);
    const topRight = turfDestination(center, hypotenuse / 2, bearingTopRight);
    const bbox = createBbox(featureCollection([bottomLeft, topRight]));
    const bboxPoly = createBboxPolygon(bbox);

    return bboxPoly;
}

const ImageOverlay = (props:ImageOverlayProps) => {
    // new store
    // store on resizeHandleResize
    // build new bbox

    // store on rotateHandleRotate
    // build new bbox

    // store on update
    // draw
    const {
        id,
        mapRef:map,
        draw,
        imageHeight,
        imageWidth,
        bboxPoly:initialPoly,
        center,
        bucketUrl,
        viewOnly
    } = props;
    let handles:mapboxgl.Marker[];

    const requestRotate = createEvent<number>();
    const imageLoaded = createEvent<ImageStore>();
    const requestResize = createEvent<ImageStore['bboxPoly']>();
    const requestMove = createEvent<[ImageStore['center'], number]>();
    const selectImage = createEvent();
    const deselectImage = createEvent();

    const imageStore = createStore<ImageStore | null>(null);

    let drawImage;
    useEffect(() => {

        if (!map?.current) {
            return
        }
        
        const onLoad = async () => {
            const storageRef = ref(getStorage(), bucketUrl);
            const url = await getDownloadURL(storageRef);
            drawImage = getDraw(map, url);
            const bboxPoly = initialPoly || getInitialBboxPoly(center, imageHeight, imageWidth);
            const { diameter, rotationCircle } = getRotationCircle({center, bboxPoly});

            const image:ImageStore = {
                id,
                active: false,
                bboxPoly,
                center,
                diameter,
                rotationCircle
            };

            imageStore.on(imageLoaded, (state, payload) => {
                log.debug('imageStore:imageLoaded', payload);
                return payload;
            });

            imageStore.on(requestRotate, (state, degrees) => {
                if (!state) {
                    return;
                }

                const bboxPoly:ImageStore['bboxPoly'] = transformRotate(state.bboxPoly, degrees);
                return {
                    ...state,
                    bboxPoly
                };
            });

            imageStore.on(requestResize, (state, bboxPoly) => {
                if (!state){
                    return;
                }

                const { diameter, rotationCircle } = getRotationCircle({center, bboxPoly});

                return {
                    ...state,
                    diameter,
                    rotationCircle,
                    bboxPoly
                };
            });

            imageStore.on(requestMove, (state, [location, verticeIndex]) => {
                if (!state) {
                    return;
                }
                const vertices = getCoords(state.bboxPoly)[0];
                const vertex = vertices[verticeIndex];
                const distance = getDistance(vertex, location);
                const direction = getAngle(vertex, location);
                const newPoints = vertices.map(vertex => findNewPoint(vertex, direction, distance));
                const bboxPoly = polygon([newPoints]);

                return {
                    ...state,
                    bboxPoly
                };
            });

            imageStore.on(selectImage, state => {
                if (!state) {
                    return;
                }

                return {
                    ...state,
                    active: true
                };
            });

            imageStore.on(deselectImage, state => {
                if (!state) {
                    return;
                }

                return {
                    ...state,
                    active: false
                };
            });

            imageStore.watch(state => {
                if (!state) {
                    return;
                }
                drawImage(state);
                imageOverlayDrawComplete(state);
            });

            // isolate sideeffects here
            imageStore.map((state, _lastState) => {
                const lastState = _lastState as ImageStore | undefined;
                if (!state) {
                    return state;
                }

                if (state.active && !lastState) {
                    activate();
                    return state;
                }

                if (state.active && !lastState?.active) {
                    activate();
                    return state;
                }

                if (!state.active && lastState?.active) {
                    deactivate();
                    return state;
                }

                return state
            });

            imageLoaded(image);
        };

        map.current.on('load', onLoad);

        function createRotateHandle(image:ImageStore, index:number) {
            const img = new Image(20, 40); // width, height
            img.src = `${rotateSVG}`;
            const poly = getCoords(image.bboxPoly)[0];
            const marker = new mapboxgl.Marker({
                rotation: 45,
                element: img,
                offset: new mapboxgl.Point(10, 0),
                draggable: true
            })
            .setLngLat(poly[index])
            .addTo(map.current);

            let previousLocation = marker.getLngLat();
            let previousAngle:number | undefined;

            marker.on('drag', () => {
                const imageState = imageStore.getState();
                if (!imageState) {
                    return;
                }

                const { rotationCircle, center } = imageState;
                const lngLat = marker.getLngLat();
                // snap location to the drag circle
                const nearestPoint = getNearestPoint([lngLat.lng, lngLat.lat], rotationCircle);
                // get the coords of that location
                const coords = getCoords(nearestPoint);
                // snap the marker
                marker.setLngLat(coords as mapboxgl.LngLatLike);
                // get the angle of the previous location of the snapped location if we don't have it
                if (!previousAngle) {
                    previousAngle = getAngle(center, [previousLocation.lng, previousLocation.lat])
                }
                // get the angle of the new snapped location
                const newAngle = getAngle(center, getCoords(nearestPoint));
                // get the angle difference
                const angleDiff = previousAngle - newAngle;
                // rotate the image
                requestRotate(angleDiff);
                previousAngle = newAngle;
                // rotate(angleDiff);
            });

            imageStore.watch(state => {
                if (!state) {
                    return;
                }

                const poly = getCoords(state?.bboxPoly)[0];
                previousLocation = poly[index];
                previousAngle = undefined;
                marker.setLngLat(poly[index]);
            });

            return marker;
        }

        // this function could be reused for a skew function
        // function createResizeHandle(image:ImageStore, index:number) {
        //     const img = new Image(20, 40); // width, height
        //     img.src = `${stretchSVG}`;

        //     const poly = getCoords(image.bboxPoly)[0];
        //     const marker = new mapboxgl.Marker({
        //         element: img,
        //         offset: new mapboxgl.Point(10, 0),
        //         draggable: true
        //     })
        //     .setLngLat(poly[index])
        //     .addTo(map.current);


        //     marker.on('drag', (e) => {
        //         const imageState = imageStore.getState();
        //         if (!imageState) {
        //             return;
        //         }

        //         const poly = getCoords(imageState?.bboxPoly)[0];
        //         // move bbox coordinate based on this handle
        //         const {lng, lat} = marker.getLngLat();
        //         // current marker is replace poly[0] so include new
        //         // lng,lat + opposite vertex (poly[2])
        //         const newPoints = [...poly];
        //         newPoints[index] = [lng,lat];
        //         // last vertice must match first
        //         newPoints[4] = newPoints[0];
        //         const newPoly = polygon([newPoints]);
        //         requestResize(newPoly);
        //     });

        //     imageStore.watch(state => {
        //         if (!state) {
        //             return;
        //         }

        //         const poly = getCoords(state?.bboxPoly)[0];
        //         marker.setLngLat(poly[index]);
        //     });

        //     return marker;
        // };

        function createResizeHandle(image:ImageStore, vertexIndex:number) {
            const img = new Image(20, 40); // width, height
            let dragStartLocation:number[];
            let dragStartDistanceToCenter;
            let dragLine:Feature<LineString>;
            img.src = `${stretchSVG}`;

            const poly = getCoords(image.bboxPoly)[0];
            const marker = new mapboxgl.Marker({
                element: img,
                offset: new mapboxgl.Point(10, 0),
                draggable: true
            })
            .setLngLat(poly[vertexIndex])
            .addTo(map.current);

            marker.on('dragstart', () => {
                const {lng, lat} = marker.getLngLat();
                dragStartLocation = [lng, lat];
                dragStartDistanceToCenter = getDistance(image.center, dragStartLocation);
                dragLine = lineString([image.center, dragStartLocation]);
            });

            marker.on('drag', (e) => {
                const imageState = imageStore.getState();
                if (!imageState) {
                    return;
                }

                // get first 4 coordinates then add 5th coordinate after coord manipulation
                const poly = getCoords(imageState?.bboxPoly)[0].slice(0,4);
                // move bbox coordinate based on this handle
                const {lng, lat} = marker.getLngLat();
                // coordinates in image are clockwise
                const newPoints = buildPolyFromMove(poly, vertexIndex, [lng, lat]);

                // const newPoints = [...poly];
                // newPoints[index] = [lng,lat];
                // // last vertice must match first
                newPoints[4] = newPoints[0];
                const newPoly = polygon([newPoints]);
                requestResize(newPoly);
            });

            imageStore.watch(state => {
                if (!state) {
                    return;
                }

                const poly = getCoords(state?.bboxPoly)[0];
                marker.setLngLat(poly[vertexIndex]);
            });
            
            return marker;
        };

        function createMoveHandle(image:ImageStore, index:number) {
            const img = new Image(20, 40); // width, height
            img.src = `${dragSVG}`;

            const poly = getCoords(image.bboxPoly)[0];
            const marker = new mapboxgl.Marker({
                element: img,
                offset: new mapboxgl.Point(10, 0),
                draggable: true
            })
            .setLngLat(poly[index])
            .addTo(map.current);


            marker.on('drag', (e) => {
                const {lng, lat} = marker.getLngLat();
                requestMove([[lng, lat], index]);
            });

            imageStore.watch(state => {
                if (!state) {
                    return;
                }

                const poly = getCoords(state?.bboxPoly)[0];
                marker.setLngLat(poly[index]);
            });

            return marker;
        };

        
        function activate() {
            log.debug('selecting', id);
            map.current.setPaintProperty('raster-layer', 'raster-opacity', RASTER_OPACITY_ACTIVE);
            map.current.setLayoutProperty('test-outline', 'visibility', 'visible');
            handles = [
                createResizeHandle(imageStore.getState() as ImageStore, 0),
                createRotateHandle(imageStore.getState() as ImageStore, 2),
                createMoveHandle(imageStore.getState() as ImageStore, 3)
            ];
        }

        function deactivate() {
            map.current.setPaintProperty('raster-layer', 'raster-opacity', RASTER_OPACITY_DEFAULT);
            map.current.setLayoutProperty('test-outline', 'visibility', 'none');
            handles.forEach(handle => handle.remove());
        }

        const onClick = (e) => {
            const imageState = imageStore.getState();
            console.log('imagestate', imageState);
            if (!imageState) {
                return;
            }

            const featureIds = draw.current.getFeatureIdsAt(e.point);
            if (featureIds.length) {
                // other features have been selected so deselect and move on
                // deselect();
                deselectImage();
                return;
            }


            const withinBounds = pointWithinPolygon(point([e.lngLat.lng, e.lngLat.lat]), imageState.bboxPoly);

            if (withinBounds && imageState.active) {
                // do nothing
                return;
            }

            if (withinBounds && !imageState.active) {
                selectImage();
                return
            }

            deselectImage();
        };

        // TODO move onclick to overlays component to avoid adding so many listeners
        // move activate to an event and update component 'active' state
        if (!viewOnly) {
            map.current.on('click', onClick);
        }

        return () => {
            map.current.off('load', onLoad);
            map.current.off('click', onClick);
        };
        
    });

    return null;
}

export default ImageOverlay;