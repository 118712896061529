import { createEvent, createStore } from 'effector';
import { useStore, useStoreMap } from 'effector-react';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';

import { FullScreenLoader } from '../../components/Loader';

import { BasicMap, MapControlPosition, WithDraw, WithGeocoder, WithGeolocate, WithMenuPositionMonitor } from '../../components/Map';
import { WithAuth } from '../../components/Auth';
import {
    activeMapStore,
    fetchMap,
    unmount,
    mapAnnotationsStore,
    init,
    resetStore
} from '../mapEdit/store';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import MapMenu from '../../components/MapMenu';
import { queryParamsLoaded } from '../../lib/appEvents';
import { useQuery } from '../../lib/hooks';
import { WithSelectedFeatureUrlTracking } from '../mapEdit';
import Navbar, { WithUserDropdown, WithBrand } from '../../components/Navbar';
import { AuthData } from '../../lib/auth/store';
import { MapData } from '../../../functions/src/map';
import { NAVBAR_HEIGHT } from '../../constants';
import ImageOverlays from '../../components/ImageOverlay/ImageOverlays';
import { requestImageOverlays, resetStore as resetOverlayStore  } from '../../components/ImageOverlay/store';

interface VisualMapStore {
    center?: MapData['center'],
    zoom?: MapData['zoom']
}
type MaybeVisualMapStore = null | VisualMapStore;

const visualMapStore = createStore<MaybeVisualMapStore>(null);
const mapMove = createEvent<MapData['center']>();
const mapZoom = createEvent<MapData['zoom']>();

visualMapStore.on(mapMove, (state, payload) => {
    return {
        ...state,
        center: payload
    }
});

visualMapStore.on(mapZoom, (state, payload) => {
    return {
        ...state,
        zoom: payload
    }
});

// const NavWithUser = WithUserDropdown(WithBrand(Navbar, '/maps'));
function NavWithUser(props) {
    return WithUserDropdown(WithBrand(Navbar, '/maps'))(props);
}

function Nav() {
    const mapCenter = useStoreMap(visualMapStore, data => data?.center);

    const activeMap = useStoreMap(activeMapStore, ({data}) => {
        if (!data) {
            return null
        }
        const { name, id, center, zoom } = data;
        return { name, id, center, zoom };
    });

    if (!activeMap) {
        return <div>Loading Nav</div>
    }

    const { name } = activeMap;

    return (
        <NavWithUser
            brandItems={() => (
                <Link className="navbar-item" to="/maps">
                    {/* <img src={logo} alt="Mapnotes" /> */}
                    Mapnotes
                </Link>
            )}
            menuStart={() => (
                <div className='navbar-item'>
                    <span>{name}</span>
                </div>
            )}
            menuEnd={() => (
                <div className="navbar-item">
                    {JSON.stringify(mapCenter)}
                </div>
            )}
        />
    );
}

// function MapSource({mapRef}) {
//     const annotations = useStore(mapAnnotationsStore);
//     useEffect(() => {
//         if (!Object.keys(annotations).length) {
//             return;
//         }
//         const id = 'annotation-source';
//         const data = {
//             type: 'geojson',
//             data: {
//                 type: 'FeatureCollection',
//                 features: Object.values(annotations)
//             }
//         };
//         // console.log('data ', JSON.stringify(data, null, 2));
//         mapRef.current.on('load', () => {
//             const source = mapRef.current.getSource(id);
//             if (source) {
//                 source.setData(data);
//             } else {
//                 mapRef.current.addSource(id, data);
//             }

//             const layers = [{
//                 id: 'text-layer',
//                 type: 'symbol',
//                 layout: {
//                     // get the title name from the source's "name" property
//                     // TODO 
//                     'text-field': ['get', 'name'],
//                     'text-font': [
//                         'Open Sans Semibold',
//                         'Arial Unicode MS Bold'
//                     ],
//                     'text-offset': [0, 1.5],
//                     'text-anchor': 'bottom'
//                 },
//                 paint: {
//                     'text-color': '#fff'
//                 }
//             }, {
//                 id: 'fill-layer',
//                 type: 'fill',
//                 paint: {
//                     'fill-color': ['get', 'color'],
//                     'fill-outline-color': '#3bb2d0',
//                     'fill-opacity': 0.1
//                 }
//             }, {
//                 'id': 'gl-draw-polygon-fill-static',
//                 'type': 'fill',
//                 'filter': ['all', ['==', '$type', 'Polygon']],
//                 'paint': {
//                 'fill-color': '#3bb2d0',
//                 'fill-outline-color': '#3bb2d0',
//                 'fill-opacity': 0.1
//                 }
//             },
//             {
//                 'id': 'gl-draw-polygon-stroke-static',
//                 'type': 'line',
//                 'filter': ['all', ['==', '$type', 'Polygon']],
//                 'layout': {
//                 'line-cap': 'round',
//                 'line-join': 'round'
//                 },
//                 'paint': {
//                 'line-color': '#3bb2d0',
//                 'line-width': 2
//                 }
//             },
//             {
//                 'id': 'gl-draw-line-static',
//                 'type': 'line',
//                 'filter': ['all', ['==', '$type', 'LineString']],
//                 'layout': {
//                 'line-cap': 'round',
//                 'line-join': 'round'
//                 },
//                 'paint': {
//                 'line-color': '#3bb2d0',
//                 'line-width': 2
//                 }
//             },
//             {
//                 'id': 'gl-draw-point-static',
//                 'type': 'circle',
//                 'filter': ['all', ['==', '$type', 'Point']],
//                 'paint': {
//                 'circle-radius': 5,
//                 'circle-color': '#3bb2d0'
//                 }
//             }];

//             const layersToAdd = layers.filter(layer => !mapRef.current.getLayer(layer.id));
//             layersToAdd.forEach(layer => {
//                 mapRef.current.addLayer({
//                     ...layer,
//                     source: id
//                 })
//             })
//         });
        
//     });

//     return <></>;
// }

// const MapViewMap = WithMenuPositionMonitor(WithGeolocate(WithDraw(BasicMap, drawOptions)));
const defaultDrawOptions = {
    controls: {}
};

function MapViewMap(props){
    return WithMenuPositionMonitor(
        WithGeolocate(
            WithGeocoder(
                WithDraw(
                    BasicMap,
                    defaultDrawOptions, MapControlPosition.BOTTOM_RIGHT
                ),
                MapControlPosition.TOP_RIGHT
            ),
            MapControlPosition.TOP_RIGHT
        )
    )(props);
}

function MapView({orgId, mapId}: {orgId: string, mapId: string}) {
    const centerRef = useRef();
    const zoomRef = useRef();
    const mapRef = useRef();
    const drawRef = useRef();
    // track center as a ref so we avoid rerender on mappage
    useEffect(() => {
        const storeMapSub = activeMapStore.watch(state => {
            if (state.data) {
                // TODO put these defaults somewhere
                const center = state.data.center || [0,0];
                const zoom = state.data.zoom || 1;

                // @ts-ignore
                centerRef.current = center;
                // @ts-ignore
                zoomRef.current = zoom;
                // store initial data
                mapMove(center)
                mapZoom(zoom)
            }
        });

        return storeMapSub;
    });

    useEffect(() => {
        // TODO this fetches twice
        fetchMap({orgId, mapId});
        return requestImageOverlays(orgId, mapId);
    },[orgId, mapId]);

    useEffect(() => {
        return init(orgId, mapId);
    }, [orgId, mapId]);


    useEffect(() => {
        // clear annotation store when leaving page
        return () => resetStore()
    });
    useEffect(() => resetOverlayStore());

    const pending = useStoreMap(activeMapStore, state => state.pending);

    if (!mapId) {
        return <Navigate to='/maps' />
    }

    if (!centerRef.current || !zoomRef.current) {
        return <FullScreenLoader message='Loading map' />
    }

    return !pending
        ? <>
            <MapViewMap {...{
                mapRef,
                draw: drawRef,
                id: mapId,
                onMove: mapMove,
                onZoom: mapZoom,
                center: centerRef.current,
                zoom: zoomRef.current
            }} />
            {/* @ts-ignore */}
            <ImageOverlays draw={drawRef} mapRef={mapRef} orgId={orgId} mapId={mapId} viewOnly={true}/>
        </>
        : <FullScreenLoader message='Loading map page' />
}

// load auth dependency first, then load page
function MapPage({auth}:{auth:AuthData}) {
    const { id: mapId } = useParams();
    const query = useQuery();

    if (!mapId) {
        return <div>Missing map id</div>
    }

    useEffect(() => {
        queryParamsLoaded({
            page: 'mapEdit',
            params: {
                annotationIds: query.get('annotationIds')?.split(',') || null
            }
        });
    }, []);

    return <>
        <Nav />
        <div style={{position: 'relative',  height: `calc(100vh - ${NAVBAR_HEIGHT})`}}>
                <MapView orgId={auth.orgId} mapId={mapId} />
                <MapMenu readonly={true} />
        </div>
    </>;
}

export default WithAuth(WithSelectedFeatureUrlTracking(MapPage));