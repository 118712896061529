import { faAnglesRight, faBezierCurve, faCamera, faChevronLeft, faDrawPolygon, faFill, faFont, faImage, faMapPin, faPhotoFilm } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {isMobile} from 'react-device-detect';
import classNames from 'classnames';
import { createEvent } from 'effector';
import { useStore } from 'effector-react';
import { getDownloadURL, ref } from 'firebase/storage';
import * as React from 'react';
import tinycolor from 'tinycolor2';

import { MapAnnotation } from '../../functions/src/annotations';
import { getStorage } from '../lib/firebase';
import { mapAnnotationsStore, MapAnnotationStore } from '../pages/mapEdit/store';
import DrawerPanel from './DrawerPanel';
import Panel, { PanelProps } from './Panel';
import { Input } from './Inputs';
import { createRef, useEffect, useRef, useState } from 'react';
import WithOffClick from './WithOffClick';
import ColorPalette from './ColorPalette';
import centroid from '@turf/centroid';
import TextAnchorSelector from './TextAnchorSelector';
import { WithAuth, WithAuthProps } from './Auth';
import { UserRole } from '../../functions/src/organization';
import { SelectedFeatureIdStore, selectedFeatureIdStore } from './Map';

const EDITABLE_MENU_PROPERTIES = ['name'];
const READONLY_MENU_PROPERTIES = ['name']

interface MapMenuProps {
    onPropertyChange?: PropertyMenuProps['onPropertyChange']
    readonly?: boolean
}

interface ReadOnlyPropertyMenuProps {
    selectedFeature: MapAnnotation
    onBackClick: () => any
}

interface PropertyMenuProps extends ReadOnlyPropertyMenuProps{
    onPropertyChange?: (id: MapAnnotation['id'], properties: MapAnnotation['properties']) => any
    onInputClick: () => any,
    onInputOffClick: (e:Event) => any
}

interface AnnotationListProps {
    selectedFeatures: SelectedFeatureIdStore['activeIds']
    annotations: MapAnnotationStore
    onAnnotationClick: (MapAnnotation) => any
}

interface MenuPosition {
    y: number
}

export const menuAnnotationSelected = createEvent<MapAnnotation>();
export const menuAnnotationDeselect = createEvent<MapAnnotation>();
export const menuPositionChanged = createEvent<MenuPosition>();

const ANNOTATION_TYPE_ICONS = {
    Point: faMapPin,
    Polygon: faDrawPolygon,
    LineString: faBezierCurve
}

function PropertyMenuImageBlock(props) {
    const [imageSources, setImageSources] = React.useState<string[]>([]);
    const {images} = props;
    React.useEffect(() => {
        Promise.all(Object.entries(images).map(([key, data]) => {
            const storageRef = ref(getStorage(), key);
            return getDownloadURL(storageRef)
        }))
        .then(results => setImageSources(results));
    }, []);

    const imageBlocks = imageSources.map(src => (
        <div className='panel-block' key={src}>
            <figure className="image">
                <img src={src} />
            </figure>
        </div>
    ));

    return <>{imageBlocks}</>
}

const NoNotes = WithAuth(({auth}:WithAuthProps) => {
    return auth.roles.indexOf(UserRole.VIEWER) >= 0
        ? <div className='panel-block'>This map has no notes.</div>
        : <div className='panel-block'>Use the tools on the map to add markers and notes to the map.</div>
});

function AnnotationList(props:AnnotationListProps) {
    const { annotations, selectedFeatures, onAnnotationClick } = props;
    const listRefs = Object.keys(annotations).reduce((acc, id)=> {
        acc[id] = createRef();
        return acc;
    }, {});
    const clickedId = useRef('');

    useEffect(() => {
        const keys = Object.keys(selectedFeatures);
        if (!keys.length) {
            return;
        }
        // scrollto the first feature
        const firstActiveId = Object.keys(selectedFeatures)[0];
        if (!listRefs[firstActiveId]) {
            return;
        }

        // if we clicked this element, don't scroll
        if (clickedId.current === firstActiveId) {
            return;
        }

        listRefs[firstActiveId].current.scrollIntoView();
    });

    const list = Object.entries(annotations).map(([id, annotation]) => {
        const type = annotation.geometry.type;
        const Icon = ANNOTATION_TYPE_ICONS[type] && <FontAwesomeIcon icon={ANNOTATION_TYPE_ICONS[type]} />;
        const imageCount = Object.keys(annotation.properties?.images || {}).length;
        const val = annotation.properties?.name || '';
        const color = annotation.properties?.color;
        const isActive = selectedFeatures[id];
        const classes = classNames('panel-block', {
            // 'is-active': isActive
        });
        const iconStyle = {
            color: ''
        };
        const itemStyle = {
            backgroundColor: ''
        };
        // only set color if not active
        if (color) {
            iconStyle.color = color;
        }

        if (isActive) {
            itemStyle.backgroundColor = '#f5f5f5'
        }

        function handleClick() {
            clickedId.current = annotation.id;
            menuAnnotationSelected(annotation);
            onAnnotationClick(annotation);
        }

        return (
            <a key={id} className={classes} onClick={handleClick} style={itemStyle} ref={listRefs[id]}>
                <span className="panel-icon" style={iconStyle}>
                    {Icon || type}
                </span>
                <span>{val}</span>
                {!isActive && imageCount > 0
                    ? <span className="panel-icon" style={{marginLeft: 'auto'}}>
                        <FontAwesomeIcon icon={faImage} />
                    </span>
                    : null
                }
                {isActive
                    ? <span className="panel-icon" style={{marginLeft: 'auto'}}>
                        <FontAwesomeIcon icon={faAnglesRight} />
                    </span>
                    : null
                }
                
            </a>
        );
    });

    return <>
            {list.length ? list : <NoNotes />}
        </>
}

function getAnnotationListData(props:AnnotationListProps) {

    return {
        collapsible: true,
        panelHeading: () => 'Notes',
        children: <AnnotationList {...props} />
    };
}

// const InputWithOffClick = WithOffClick(Input);

function InputWithOffClick(props) {
    return WithOffClick(Input)(props);
}

function getPropertyMenuData(props:PropertyMenuProps):PanelProps {
    const {
        onPropertyChange,
        selectedFeature,
        onBackClick,
        onInputClick,
        onInputOffClick
    } = props;
    const properties = selectedFeature?.properties || {};
    const { images = {}, color, textColor, textAnchor, ...otherProperties } = properties;

    const handlePropertyChange = (key) => (e) => {
        const newProperties = {...properties, [key]: e.target.value};
        if (onPropertyChange) {
            onPropertyChange(selectedFeature?.id, newProperties);
        }
    };

    const handleColorChange = (color) => {
        const deselect = properties.color === color;
        const newProperties = {...properties, color};
        if (deselect) {
            delete newProperties.color;
        }

        if (onPropertyChange) {
            onPropertyChange(selectedFeature?.id, newProperties);
        }
    }

    const handleFontColorChange = textColor => {
        const deselect = properties.textColor === textColor;
        const newProperties = {...properties, textColor};
        if (deselect) {
            delete newProperties.textColor;
        } else {
            // set text halo color as well
            const color = tinycolor(newProperties.textColor);
            newProperties.textHaloColor = color?.isLight() ? color.darken(90).toString() : color?.lighten(90).toString();
        }

        if (onPropertyChange) {
            onPropertyChange(selectedFeature?.id, newProperties);
        }
    }

    const handleTextAnchorChange = textAnchor => {
        const deselect = properties.textAnchor === textAnchor;
        const newProperties = {...properties, textAnchor};

        if (deselect) {
            delete newProperties.textAnchor;
        }
        
        if (onPropertyChange) {
            onPropertyChange(selectedFeature?.id, newProperties);
        }
    }

    if (!otherProperties.name) {
        otherProperties.name = '';
    }

    function propertyBlocks() {
        return EDITABLE_MENU_PROPERTIES.map((key) => (
            <div className='panel-block' key={key}>
                <InputWithOffClick
                    type='text'
                    placeholder={key}
                    value={otherProperties[key]}
                    onChange={handlePropertyChange(key)}
                    onClick={onInputClick}
                    offClick={onInputOffClick}
                />
            </div>
        ));
    }

    const panelTabs = [{
        title: () => 'Properties',
        content: () => {
            // @ts-ignore - poor typing in turf
            const center = centroid(selectedFeature.geometry);
            const [lng, lat] = center.geometry.coordinates;
            return (<>
                {propertyBlocks()}
                <div className='panel-block'>
                    <span className='panel-icon'>
                        <FontAwesomeIcon icon={faFill} />
                    </span>
                    <ColorPalette onClick={color => handleColorChange(color)} selected={color}/>
                </div>
                <div className='panel-block'>
                    <span className='panel-icon'>
                        <FontAwesomeIcon icon={faFont} />
                    </span>
                    <ColorPalette onClick={color => handleFontColorChange(color)} selected={textColor}/>
                </div>
                <div className="panel-block">
                    <div className="field">
                        <span className='label'>Text Position</span>
                        <div className='control'>
                            <TextAnchorSelector onClick={option => handleTextAnchorChange(option)} selected={textAnchor}/>
                        </div>
                    </div>
                </div>
                <div className='panel-block'>
                    <div className="field">
                        <span className='label'>Longitude</span>
                        <div className='control'>
                            {lng}
                        </div>
                    </div>
                </div>
                <div className='panel-block'>
                    <div className="field">
                        <span className='label'>Latitude</span>
                        <div className='control'>
                            {lat}
                        </div>
                    </div>
                </div>
            </>)
        }
    }, {
        title: () => `Images (${Object.keys(images).length})`,
        content: () => (
            Object.keys(images).length
            ? <PropertyMenuImageBlock images={images} />
            : <div className='panel-block'>
                <p>Use the camera tool (<span className="icon"><FontAwesomeIcon icon={faImage} /></span>) to upload GPS embedded images.</p>
              </div>
        )
    }];

    const panelHeading = () => (
        <a className='icon-text' onClick={onBackClick}>
            <span className="icon"> <FontAwesomeIcon icon={faChevronLeft} /></span>
            <span>Back</span>
        </a>
    );

    return {
        collapsible: false,
        panelTabs,
        panelHeading
    };
}

function getReadonlyPropertyMenuData(props:ReadOnlyPropertyMenuProps) {
    const {
        selectedFeature,
        onBackClick
    } = props;

    const properties = selectedFeature?.properties || {};
    const { images = {}, ...otherProperties } = properties;

    if (!otherProperties.name) {
        otherProperties.name = '';
    }

    function propertyBlocks() {
        // @ts-ignore - poor typing in turf
        const center = centroid(selectedFeature.geometry);
        const [lng, lat] = center.geometry.coordinates;
        return (<>
            {READONLY_MENU_PROPERTIES.map((key) => (
                <div className='panel-block' key={key}>
                {key}: {otherProperties[key]}
                </div>
            ))}

            <div className='panel-block'>
                <div className="field">
                    <span className='label'>Longitude</span>
                    <div className='control'>
                        {lng}
                    </div>
                </div>
            </div>
            <div className='panel-block'>
                <div className="field">
                    <span className='label'>Latitude</span>
                    <div className='control'>
                        {lat}
                    </div>
                </div>
            </div>
        </>);
    }

    const tabs = [{
        title: () => 'Properties',
        content: () => <>{propertyBlocks()}</>
    }, {
        title: () => `Images (${Object.keys(images).length})`,
        content: () => <PropertyMenuImageBlock images={images} />
    }];

    const panelHeading = () => (
        <a className='icon-text' onClick={onBackClick}>
            <span className="icon"> <FontAwesomeIcon icon={faChevronLeft} /></span>
            <span>Notes</span>
        </a>
    );
    const panelTabs = tabs;

    return {
        collapsible: false,
        panelTabs,
        panelHeading
    };
}


export default function MapMenu(props:MapMenuProps) {
    const selectedFeatureIds = useStore(selectedFeatureIdStore);
    const annotations = useStore(mapAnnotationsStore);
    const [showDetail, setShowDetail] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    const data:PanelProps = (Object.keys(selectedFeatureIds.activeIds).length === 1 && showDetail)
        ? (!props.readonly) 
            ? getPropertyMenuData({
                selectedFeature: annotations[Object.keys(selectedFeatureIds.activeIds)[0]],
                onBackClick: () => {setShowDetail(false)},
                onInputClick: () => setFullscreen(true),
                onInputOffClick: (e) => {
                    setFullscreen(false);
                },
                ...props
            })
            : getReadonlyPropertyMenuData({
                onBackClick: () => {setShowDetail(false)},
                selectedFeature: annotations[Object.keys(selectedFeatureIds.activeIds)[0]],
                ...props
            })
        : getAnnotationListData({
            selectedFeatures: selectedFeatureIds.activeIds,
            annotations,
            onAnnotationClick: (annotation) => {
                if (selectedFeatureIds.activeIds[annotation.id]) {
                    setShowDetail(true);
                } else {
                    setShowDetail(false);
                }
            }
        });

    const Component = isMobile ? DrawerPanel : Panel;

    function handlePanelChange(position) {
        menuPositionChanged(position);
    }

    return <div id="map-menu">
            <Component
                collapsible={data.collapsible}
                panelHeading={data.panelHeading}
                panelTabs={data.panelTabs}
                // @ts-ignore - onChange isn't a prop in the panel component but that 
                // isn't important here
                onChange={handlePanelChange}
                fullscreen={fullscreen}
            >
                    {data.children
            }</Component>
    </div>
}