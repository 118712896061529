import * as React from 'react';
import { useEffect } from 'react';

import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import Auth from './components/Auth';
import Navbar from './components/MainNav';
import Maps from './pages/maps/Maps';
import MapEditPage from './pages/mapEdit';
import MapViewPage from './pages/mapView';
import Home from './pages/home';
import AccountPage, { AccountTab } from './pages/account';

import { useStore } from 'effector-react';
import { authStore } from './lib/auth/store';
import Footer from './components/Footer';

export const ProtectedComponent = ({children}) => {
  const auth = useStore(authStore);
  if (auth.initialized && !auth.pending && !auth.data?.user) {
    return <Navigate to='/' />
  }
  return children;
}

function App() {
  return (
    <div>
      <Routes>
        <Route path="/maps" element={
          <ProtectedComponent>
              <Navbar /><Maps /><Footer />
          </ProtectedComponent>}
        />
        <Route path="/account/:tab" element={
          <ProtectedComponent>
            <Navbar /><AccountPage /><Footer />
          </ProtectedComponent>}
        />
        <Route path="/account" element={
          <ProtectedComponent>
            
              <Navigate to={`/account/${AccountTab.general}`} /><Footer />
            
          </ProtectedComponent>}
        />
        <Route path="/map/:id/edit" element={<ProtectedComponent><MapEditPage /></ProtectedComponent>} />
        <Route path="/map/:id/view" element={<ProtectedComponent><MapViewPage /></ProtectedComponent>} />

        <Route path="/" element={<div className="has-fixed-footer"><Home/><Footer fixed={true}/></div>} />
        <Route path="*" element={<div className="has-fixed-footer"><NoMatch /><Footer fixed={true}/></div>} />
      </Routes>
    </div>
  );
}

function NoMatch() {

  const location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export default App;