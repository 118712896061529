import { createEvent, createStore } from 'effector';
import { useStoreMap } from 'effector-react';
import * as React from 'react';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MapAnnotation } from '../../../functions/src/annotations';
import MapNotificationControl from '../../components/MapNotificationControl';
import { FullScreenLoader } from '../../components/Loader';

import Map, { selectedFeatureIdStore } from '../../components/Map';
import MapMenu from '../../components/MapMenu';
import { WithAuth } from '../../components/Auth';
import { queryParamsLoaded } from '../../lib/appEvents';
import { useQuery } from '../../lib/hooks';
import { AuthData } from '../../lib/auth/store';
import { getClipboardProps } from '../../components/MapClipBoard';
import log from '../../lib/log';
import {
    localAnnotationPropertiesModified,
    mapNameModified,
    activeMapStore,
    fetchMap,
    setMapCenter,
    mapDrawCreate,
    mapDrawUpdate,
    mapDrawDelete,
    init,
    pinDropRequested,
    resetStore,
    mapAnnotationsStore
} from './store';
import Navbar, { WithUserDropdown, WithBrand } from '../../components/Navbar';
import WithOffClick from '../../components/WithOffClick';
import { MapData } from '../../../functions/src/map';
import MapControlBox from '../../components/MapControlBox';
import { NAVBAR_HEIGHT } from '../../constants';
import { pasteRequested } from './events';
import ImageOverlays from '../../components/ImageOverlay/ImageOverlays';
import { requestImageOverlays, resetStore as resetOverlayStore } from '../../components/ImageOverlay/store';
// import logo from '../../img/mapnotes.png';

export interface MapEditQueryParams {
    page: 'mapEdit',
    params: {
        annotationIds: MapAnnotation['id'][] | null
    }
}

interface VisualMapStore {
    center?: MapData['center'],
    zoom?: MapData['zoom']
}
type MaybeVisualMapStore = null | VisualMapStore;

const visualMapStore = createStore<MaybeVisualMapStore>(null);
const mapMove = createEvent<MapData['center']>();
const mapZoom = createEvent<MapData['zoom']>();
export const geoLocatePositionReceived = createEvent<GeolocationPosition>();
export const trackuserlocationended = createEvent();

visualMapStore.on(mapMove, (state, payload) => {
    log.debug('visualMapStore:mapMove', payload);
    const currentCenter = state?.center || [];
    // prevent unnecessary store changes when the map didn't actually move.
    // mapboxgljs events trigger even when the map didn't actually move
    if (payload[0] === currentCenter[0] && payload[1] === currentCenter[1]) {
        return state;
    }
    return {
        ...state,
        center: payload
    }
});

visualMapStore.on(mapZoom, (state, payload) => {
    log.debug('visualMapStore:mapZoom', payload);
    return {
        ...state,
        zoom: payload
    }
});

function NameField({value, onChange, inputRef, ...props}) {
    const [internalValue, setValue] = useState(value);
    const handleChange = (e) => {
        setValue(e.target.value);
        onChange(e);
    }
    // inputRef is necessary for withoffclick
    const ref = inputRef || useRef(null);

    return <div className="control">
        <input
            autoFocus
            className="input"
            type="text"
            value={internalValue}
            onChange={handleChange}
            ref={ref}
            {...props}
        />
    </div>
}

// const NameFieldWithOffClick = WithOffClick(NameField);
function NameFieldWithOffClick(props) {
    return WithOffClick(NameField)(props);
}

function Name({name, onChange}) {
    const [editable, setEditable] = useState(false);
    const [value, setValue] = useState(name);
    const wasOffClick = useRef(false);

    useEffect(() => {
        if (wasOffClick.current) {
            setEditable(false);
            onChange(value);
            // reset wasOffClick
            wasOffClick.current = false;
        }
    }, [wasOffClick.current]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setEditable(false);
            onChange(value);
        }
    }
    const handleChange = (event) => {
        setValue(event.target.value);
    }

    // return <div className="navbar-item" onClick={() => setEditable(true)}>
    return editable
        ? <NameFieldWithOffClick
            value={name}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            offClick={() => {
                wasOffClick.current = true;
                // once editable is true, a rerender occurs and our new value is propogated 
                setEditable(false);
            }}
            />
        : <span onClick={() => setEditable(true)}>{name}</span>
}

// WithBrand is forcing the dropdown to render repeatedly. Memoizing is a bandaid. 
const NavWithUser = React.memo(WithUserDropdown(WithBrand(Navbar, '/maps')));
NavWithUser.displayName = 'NavWithUser';

function Nav() {
    const mapCenter = useStoreMap(visualMapStore, data => data?.center);
    const mapZoom = useStoreMap(visualMapStore, data => data?.zoom);
    const activeMap = useStoreMap(activeMapStore, ({data}) => {
        if (!data) {
            return null
        }
        const { name, id, center, zoom } = data;
        return { name, id, center, zoom };
    });

    if (!activeMap) {
        return <div>Loading Nav</div>
    }

    const {id, name, center, zoom} = activeMap;

    return (
        <NavWithUser
            menuStart={() => (
                <div className="navbar-item is-hoverable">
                    <Name name={name} onChange={newName => mapNameModified({id, name: newName})} />
                </div>
            )}
            menuEnd={() => (
                <div className="navbar-item">
                    {JSON.stringify(mapCenter?.map(num => num.toFixed(8)))}
                    <button
                        className="button"
                        disabled={mapCenter === center && mapZoom === zoom}
                        onClick={() => (mapCenter && mapZoom) && setMapCenter({center: mapCenter, zoom: mapZoom})}
                    >
                            Set View Position
                    </button>
                </div>
            )}
        />
    );
}

const handlePropertyChange = (id: MapAnnotation['id'], properties: MapAnnotation['properties']) => {
    localAnnotationPropertiesModified({id, properties, modified: Date.now()});
}

function MapEditor({orgId, mapId}: {orgId: string, mapId: string}) {
    const centerRef = useRef();
    const zoomRef = useRef();
    const mapRef = useRef();
    const drawRef = useRef();
    const clipBoard = useRef<MapAnnotation[]>([]);
    const geolocationPosition = useRef<GeolocationPosition>();
    // track center as a ref so we avoid rerender on mappage
    useEffect(() => {
        const storeMapSub = activeMapStore.watch(state => {
            if (state.data) {
                // TODO put these defaults somewhere
                const center = state.data.center || [0,0];
                const zoom = state.data.zoom || 1;

                // @ts-ignore
                centerRef.current = center;
                // @ts-ignore
                zoomRef.current = zoom;
                // store initial data
                mapMove(center)
                mapZoom(zoom)
            }
        });

        return storeMapSub;
    });

    useEffect(() => {
        // TODO this fetches twice
        fetchMap({orgId, mapId});
        return requestImageOverlays(orgId, mapId);
    },[orgId, mapId]);

    useEffect(() => {
        return init(orgId, mapId);
    }, [orgId, mapId]);

    useEffect(() => {
        // clear annotation store when leaving page
        return () => resetStore()
    });
    useEffect(() => resetOverlayStore());


    const pending = useStoreMap(activeMapStore, state => state.pending);

    if (!mapId) {
        // TODO redirect to maps
        return (<div>ID is undefined</div>)
    }

    if (!centerRef.current || !zoomRef.current) {
        return <FullScreenLoader message='Loading map' />
    }
    
    const onCopy =  () => {
        const annotations = mapAnnotationsStore.getState();
        const activeAnnotations = Object.keys(selectedFeatureIdStore.getState().activeIds)
            .map(id => annotations[id]);
        clipBoard.current = activeAnnotations;
    };
    const onPaste = () => {
        if (clipBoard.current.length) {
            pasteRequested(clipBoard.current);
            clipBoard.current = [];
        }
    };
    
    return !pending
        ? <div {...getClipboardProps({onCopy, onPaste})}>
            <Nav />
                <div style={{position: 'relative', height: `calc(100vh - ${NAVBAR_HEIGHT})`}}>
                    <MapNotificationControl />
                    <Map {
                        ...{
                            uploadStoragePath: `organizations/${orgId}/maps/${mapId}/annotations`,
                            mapRef,
                            draw: drawRef,
                            id: mapId,
                            onMove: mapMove,
                            onZoom: mapZoom,
                            center: centerRef.current,
                            zoom: zoomRef.current,
                            onDrawCreate: (event) => mapDrawCreate({orgId, mapId, event}),
                            onDrawDelete: (event) => mapDrawDelete({orgId, mapId, event}),
                            onDrawUpdate: (event) => mapDrawUpdate({orgId, mapId, event}),
                            onDropPinClick: (location:[number, number]) => {
                                pinDropRequested(location);
                            },
                            onTrackuserlocationend: () => {
                                trackuserlocationended();
                            },
                            onGeolocate: (position:GeolocationPosition) => {
                                geoLocatePositionReceived(position);
                                geolocationPosition.current = position;
                            }
                        }} />
                        {/* @ts-ignore */}
                        <ImageOverlays draw={drawRef} mapRef={mapRef} orgId={orgId} mapId={mapId} />
                    
                    <MapControlBox drawRef={drawRef} mapRef={mapRef}/>
                    <MapMenu onPropertyChange={handlePropertyChange} />
                </div>
        </div>
        : <div>Loading MapPage</div>
}


export const WithSelectedFeatureUrlTracking = Component => props => {
    // on mount, watch for url events we want to monitor
    useEffect(() => {
        const urlStore = selectedFeatureIdStore.map((state) => {
            return {
                annotationIds: Object.keys(state.activeIds).join(',')
            }
        });
        return urlStore.watch(urlData => {
            const url = new URL(window.location.href);
            // filter out any undefined values
            Object.entries(urlData).forEach(([key, value]) => {
                if (value) {
                    url.searchParams.set(key, value);
                } else {
                    url.searchParams.delete(key)
                }
            });
            history.pushState({}, '', url);
        });
    }, []);

    return <Component {...props} />
};


// load auth dependency first, then load page
function MapPage({auth}:{auth:AuthData}) {
    const { id: mapId } = useParams();
    const query = useQuery();

    useEffect(() => {
        queryParamsLoaded({
            page: 'mapEdit',
            params: {
                annotationIds: query.get('annotationIds')?.split(',') || null
            }
        });
    }, []);

    if (!mapId) {
        return <div>Missing map id</div>
    }
    return <MapEditor orgId={auth.orgId} mapId={mapId}></MapEditor>
}

export default WithAuth(WithSelectedFeatureUrlTracking(MapPage));