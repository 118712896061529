// import * as React from 'react';

import log from '../lib/log';

// interface MapClipboardProps {
//     mapContainerRef: any
// }

// function MapClipboard(props:MapClipboardProps) {
//     const { mapContainerRef } = props;

//     mapContainerRef.
// }

// export default MapClipboard;

export function getClipboardProps({onCopy, onPaste}) {
    let pressedButtons = {};
    const META = 'Meta';
    const CTRL = 'Control';
    const C = 'c';
    const V = 'v';

    function handleKeyDown(e) {
        // if meta or ctrl add as ctrl to state
        // if c, add to state
        // if p, add to state
        if ([META, CTRL].indexOf(e.key) > -1) {
            pressedButtons[e.key] = true;
        }

        // if state has ctrl + c,
        // or meta + c
        // copy
        if (
            (pressedButtons[META] || pressedButtons[CTRL])
            && e.key === C
        ) {
            log.debug('Copying');
            onCopy();
        }

        // if state has ctrl + v,
        // paste
        if (
            (pressedButtons[META] || pressedButtons[CTRL])
            && e.key === V
        ) {
            log.debug('Pasting');
            onPaste();
        }
    }

    function handleKeyUp(e) {
        // remove from state
        delete pressedButtons[e.key]
    }

    return {
        tabIndex: -1,
        onKeyDown: handleKeyDown,
        onKeyUp: handleKeyUp
    }

    // NEED TO FIX WHERE OUR FOCUS IS ADDED. MAYBE BODY?
    // ALSO NEED TO SELECT THE FEATURE AFTER IT IS CREATED
}