import { faArrowsUpDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as React from 'react';
import { useRef, useState } from 'react';
import { PanelProps } from './Panel';

export interface DrawerPanelProps extends PanelProps{
    onChange?: ({y}: {y:number}) => any
    fullscreen: boolean
}

const WithDragging = (Component) => (props) => {
    const [active, setActive] = useState(false);
    const [xy, setXY] = useState([0,0]);
    React.useEffect(() => {
        document.addEventListener('mousemove', onMouseMove);

        return () => document.removeEventListener('mousemove', onMouseMove);
    }, []);

    // TODO clean this up
    function onMouseMove(e) {
        if (active) {
            setXY([e.pageX, e.pageY]);
        }
    }

    return <Component
        {...props}
        onMouseDown={() => console.log('mouseeee')}
        onMouseUp={() => console.log('on mouse up')}
        onClick={() => console.log('click')}
        position={xy}
    />
}

function Draggable(props) {
    const {children, onDrag, ...otherProps} = props;
    // const [active, setActive] = useState(false);
    const active = useRef(false);
    const initialOffsetY = useRef(0);
    // const [xy, setXY] = useState([0,0]);
    React.useEffect(() => {
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('touchmove', onTouchMove);

        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('touchmove', onTouchMove);
        }
    }, []);

    function onMouseMove(e) {
        if (active.current) {
            onDrag({
                e,
                x: e.pageX,
                y: e.pageY - initialOffsetY.current
            })
        }
    }

    function onTouchMove(e) {
        // e.preventDefault();
        if(active.current) {
            onDrag({
                e,
                x: e.touches[0].pageX,
                y: e.touches[0].pageY - initialOffsetY.current
            });
        }
    }

    function handleMouseDown(e) {
        active.current = true;
        if (e.nativeEvent.offsetY) {
            initialOffsetY.current = e.nativeEvent.offsetY
        } else {
            const rect = e.target.getBoundingClientRect();
            initialOffsetY.current = e.touches[0].clientY - rect.top;
        }
    }

    function handleMouseUp(e) {
        active.current = false
    }

    return <div
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchEnd={handleMouseUp}
        {...otherProps}
    >
        {children}
    </div>
}


function DrawerPanel(props:DrawerPanelProps) {
    const DEFAULT_HEIGHT = 100;
    const DEFAULT_TOP = window.innerHeight - DEFAULT_HEIGHT;
    const [activeTab, setActiveTab] = useState(0);
    const [height, setHeight] = useState(DEFAULT_HEIGHT);
    const [top, setTop] = useState(window.innerHeight - height);
    const {
        panelHeading,
        panelTabs=[],
        onChange=()=>{},
        fullscreen = false,
        children
    } = props;
    const headingRef = useRef(null);
    const iconRef = useRef(null);

    // only enable drag if we're seleting the heading
    function handleDrag({e, y}) {
        if (e.target !== headingRef.current && e.target !== iconRef.current) {
            return;
        }
        setTop(y);
        setHeight(window.innerHeight - y);
        onChange({y});
    }

    function handleDoubleClick() {
        const y = window.innerHeight / 3;
        setTop(y)
        setHeight(window.innerHeight - y);
        onChange({y});
    }

    const style = {
        height: fullscreen
            ? '100vh'
            : height >= DEFAULT_HEIGHT ? height : DEFAULT_HEIGHT,
        top: fullscreen
            ? 0
            : top <= DEFAULT_TOP ? top : DEFAULT_TOP
    }
    
    
    return (
        <Draggable
            onDoubleClick={handleDoubleClick}
            onDrag={handleDrag}
            className="drawer-panel"
            style={style}
        >
            <p className="panel-heading" ref={headingRef}>
                {panelHeading()} <span style={{float:'right'}} ref={iconRef}><FontAwesomeIcon icon={faArrowsUpDown} /></span>
            </p>
            <div className='panel'>
                {panelTabs.length
                    ? <p className="panel-tabs">
                        {panelTabs.map((tab, index) => {
                            const className = classNames({
                                'is-active': activeTab === index
                            })
                            return <a className={className} onClick={() => setActiveTab(index)} key={index}>{tab.title()} </a>
                        })}
                    </p>
                    : null
                }
                {panelTabs.length ? panelTabs[activeTab].content() : null}
                {children}
            </div>
        </Draggable>
    );
}

export default function DraggableDrawer(props) {
    return WithDragging(DrawerPanel)(props);
};