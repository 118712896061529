import { createStore } from "effector";
import { decodeAnnotation } from "../../../functions/src/annotations";
import { serverAnnotationAdded } from "../../pages/mapEdit/store";
import { annotationImageAdded, imageUploadComplete, imageUploadError, imageUploadStarted, imageUploadErrorDismissed } from "./";

// interface ImageUploadQueue{

// }

type ImageUploadQueue = Set<string>;
type ImageProcessingQueue = Set<string>;
export interface ImageUploadErrorQueue {
    [fileName: string]: Error
}

export const imageUploadQueue = createStore<ImageUploadQueue>(new Set());
export const imageProcessingQueue = createStore<ImageProcessingQueue>(new Set());
export const imageUploadErrorQueue = createStore<ImageUploadErrorQueue>({});

imageUploadQueue.on(imageUploadStarted, (state, {fileName}) => {
    return new Set(state).add(fileName);
});

imageUploadQueue.on(imageUploadComplete, (state, {fileName}) => {
    const set = new Set(state);
    set.delete(fileName);

    return set;
});

imageProcessingQueue.on(imageUploadComplete, (state, {fileName}) => {
    return new Set(state).add(fileName);
});

// when a new annotation is added, check if it's in our processing queue and remove it
imageProcessingQueue.on(serverAnnotationAdded, (state, encodedAnotation) => {
    const annotation = decodeAnnotation(encodedAnotation);
    const images = annotation.properties?.images || [];
    const set = new Set(state);
    let newState = false;

    Object.keys(images).forEach(imageRef => {
        // get filename from imageRef
        const parts = imageRef.split('/');
        const fileName = parts[parts.length - 1];
        // if we do delete, track that so we know to return a new set object
        // if we simply return a new set object, we'll force an unnecessary rerender
        newState = set.delete(fileName);

        if (newState) {
            annotationImageAdded({id: annotation.id});
        }
    });

    return newState ? set : state;
});

imageUploadErrorQueue.on(imageUploadError, (state, {fileName, error}) => {
    return {
        ...state,
        [fileName]: error
    };
});

imageUploadErrorQueue.on(imageUploadErrorDismissed, (state, {fileName, error}) => {
    const { [fileName]: removedFile, ...newState } = state;
    return newState;
});