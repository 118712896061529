import { useStore } from 'effector-react';
import * as React from 'react';
import ImageOverlay, { ImageOverlayProps } from './ImageOverlay';
import { overlayStore } from './store';


interface WithImageOverlaysProps {
    mapRef: ImageOverlayProps['mapRef'],
    draw: ImageOverlayProps['draw'],
    viewOnly?: boolean
}

const ImageOverlays = (props:WithImageOverlaysProps) => {
    const {
        mapRef,
        draw,
        viewOnly
    } = props;

    const overlays = useStore(overlayStore);
    return <>
        {Object.entries(overlays).map(([key, overlay]) => {
            return <ImageOverlay key={key} id={key} {...overlay} mapRef={mapRef} draw={draw} viewOnly={viewOnly}/>
        })}
    </>
}

export default ImageOverlays;