import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStore } from 'effector-react';
import * as React from 'react';
import { imageUploadErrorDismissed } from '../mapControls/photoUpload';
import { imageUploadErrorQueue, imageUploadQueue, imageProcessingQueue, ImageUploadErrorQueue } from '../mapControls/photoUpload/store';

enum MessageType {
    success = 'is-success',
    info = 'is-info',
    danger = 'is-danger'
}

type OnDismiss = () => any;

type Message = [MessageType, OnDismiss, JSX.Element]

function getUploadMessage(set) {
    const plural = set.size > 1 ? 'images' : 'image';
    return `Uploading ${set.size} ${plural}.`;
}

function getProcessingMessage(set) {
    const plural = set.size > 1 ? 'images' : 'image';
    return `Processing ${set.size} ${plural}.`;
}


function getErrorMessages(uploadErrors:ImageUploadErrorQueue) {
    return Object.entries(uploadErrors).map(([filename, error]) => {
        return `Error uploading file. ${error.message}`;
    });
}

export default function MapNotificationControl() {
    const uploadErrors = useStore(imageUploadErrorQueue);
    const queuedImages = useStore(imageUploadQueue);
    const processingImages = useStore(imageProcessingQueue);

    function getMessage():Message | null {
        if (queuedImages.size > 0) {
            return [
                MessageType.info,
                () => {},
                <span className="icon-text">
                    <span className='icon'>
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" /> 
                    </span>
                    <span>
                        {getUploadMessage(queuedImages)}
                    </span>
                </span>
            ];
        } else if (processingImages.size > 0) {
            return [
                MessageType.info,
                () => {},
                <span className="icon-text">
                    <span className='icon'>
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" /> 
                    </span>
                    <span>{getProcessingMessage(processingImages)}</span>
                </span>
            ];
        } else if (Object.keys(uploadErrors).length) {
            return [
                MessageType.danger,
                () => {
                    Object.entries(uploadErrors).forEach(([fileName, error])=> {
                        imageUploadErrorDismissed({fileName, error});
                    })
                },
                <span>{getErrorMessages(uploadErrors)}</span>
            ];
        }
        
        return null;
    };

    const data = getMessage();
    const messageType = data ? data[0] : MessageType.info;
    const message = data ? data[2] : '';
    const onDismiss = data ? data[1] : () => {};

    return (
        <div
            className={`notification ${messageType} map-notifcation-control`}
            style={{
                position: 'absolute',
                top: data ? '30px' : '-100px',
                display: data ? 'block' : 'none'
            }}
        >
            <button className="delete" onClick={onDismiss}></button>
            {message}
        </div>
    )
}