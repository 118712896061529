import classNames from 'classnames';
import * as React from 'react';
import { TextAnchorPosition } from '../../functions/src/annotations';

interface TextAnchorSelectorProps {
    selected?: TextAnchorPosition
    onClick: (Option) => any
}

function TextAnchorSelector(props:TextAnchorSelectorProps) {
    const { selected, onClick } = props;
    // todo, get this from type
    const options = [
        'top-left',
        'top',
        'top-right',
        'left',
        'center',
        'right',
        'bottom-left',
        'bottom',
        'bottom-right'
    ].reverse();
    // reverse positions as anchor is opposite of what is perceived as text position
    return (
        <div className='anchor-selector'>
            {options.map(option => {
                const classes = classNames('anchor-option', {
                    'is-active': selected === option
                })
                return (
                    <div className={classes} onClick={() => onClick(option)} key={option}></div>
                );
            })}
        </div>
    )
}

export default TextAnchorSelector;