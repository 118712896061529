import * as React from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useLocation } from "react-router-dom";

import App from "./App";
import { init, logEvent } from './lib/firebase';
import { init as initAuth } from './lib/auth';


import './style.scss';

init();
initAuth();

const root = ReactDOM.createRoot(
  document.getElementById("app") as Element
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      <FirebaseAnalytics />
    </BrowserRouter> 
  </React.StrictMode>
);

function FirebaseAnalytics() {
  const location = useLocation();
  useEffect(() => {
      const page_path = location.pathname + location.search;
      logEvent('page_view', { page_path });
  }, [location]);
  return null;
}