import { getAuth } from "../firebase";
import { authStateChanged } from "./events";

export function init() {
    getAuth().onAuthStateChanged(function(user) {
        if (!user) {
            // ensures a user is logged out
            authStateChanged(null);
        } else {
            authStateChanged(user);
        }
    });
}