
import * as React from 'react';
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import { User as FirebaseUser } from '@firebase/auth-types';
import { useStore } from "effector-react";
import { UserInfo } from '@firebase/auth-types';
import { doc, getDoc, getFirestore, onSnapshot } from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as log from 'loglevel';

import 'firebaseui/dist/firebaseui.css'

import { getAuth, getFunctions, logEvent } from '../../lib/firebase';
import { AuthData, authStore, pendingAuthStore } from "../../lib/auth/store";
import { userOrgIdReceived } from '../../lib/auth/events';

import { FullScreenLoader } from '../Loader';
import ChangeOrg from './ChangeOrg';

import { Plan } from '../../../functions/src/plan';
import { Organization, UserRole } from '../../../functions/src/organization';
import { User } from '../../../functions/src/user';
import { httpsCallable } from 'firebase/functions';
import { GetCustomerRecord } from '../../../functions/src/lib.shared';
import ErrorMessage from '../ErrorMessage';

interface AuthResult {
    user: UserInfo
}

interface FirebaseAuthProps {
    onSignInFailure: () => any,
    onSignInSuccess: (authResult: AuthResult) => any
}


const functions = getFunctions();
const getCustomerPortalLink = httpsCallable<GetCustomerRecord, string>(functions, 'getCustomerPortalLink');

export function FirebaseAuth(props:FirebaseAuthProps) {
    const { onSignInFailure, onSignInSuccess } = props;
    const uiRef = useRef(null);
    useEffect(() => {
        const uiConfig = {
            signInFlow: 'popup',
            signInOptions: [
            // Leave the lines as is for the providers you want to offer your users.
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                requireDisplayName: false,
                // fullLabel: 'Create an account'
            }
            ],
            // tosUrl and privacyPolicyUrl accept either url string or a callback
            // function.
            // Terms of service url/callback.
            tosUrl: '<your-tos-url>',
            // Privacy policy url/callback.
            privacyPolicyUrl: function() {
                window.location.assign('<your-privacy-policy-url>');
            },
            callbacks: {
                signInSuccessWithAuthResult: (authResult:AuthResult) => {
                    onSignInSuccess(authResult);

                    return false;
                },
                signInFailure: () => {
                    onSignInFailure();
                }
            }
        };


        // Initialize the FirebaseUI Widget using Firebase.
        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(getAuth());
        // The start method will wait until the DOM is loaded.
        uiRef.current && ui.start(uiRef.current, uiConfig);
    }, []);

    return <div ref={uiRef}></div>;
}

function GetOrgs({onGet, firebaseUser}) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error>();
    useEffect(() => {
        getOrgIds(firebaseUser).then((orgs) => {
            setLoading(false);
            onGet(orgs);
        }).catch(error => {
            setLoading(false);
            setError(error);   
        });
    }, []);

    if (loading) {
        return <FullScreenLoader message={'Getting user organization data'} />
    }

    if (error) {
        return <ErrorMessage error={error} />
    }
    return null;
}

function getOrgIds(user:FirebaseUser):Promise<User['orgIds']> {
    const userKey = `users/${user.uid}`;
    // look at user data for orgId
    // if orgId is set, sign user in with that
    const promise:Promise<User['orgIds']> = getDoc(doc(getFirestore(), userKey)).then(snapshot => {
        const userData = snapshot.data() as User | undefined;
        let unsub;

        if (userData?.orgIds) {
            setTimeout(() => logEvent('login'));
            return userData.orgIds;
        }

        // if no org id, wait for org to be created on the backend
        // note: this is a new user
        return new Promise(resolve => {
            unsub = onSnapshot(doc(getFirestore(), userKey), (snapshot) => {
                const userData = snapshot.data() as User | undefined;
                
                if (userData?.orgIds) {
                    unsub()
                    setTimeout(() => logEvent('sign_up'));
                    resolve(userData.orgIds);
                }
            });
        });
    });

    promise.catch(e => {
        log.error('error getting user data', e);
    });

    return promise;
}

export function isAdmin(auth:AuthData) {
    return auth.roles.indexOf(UserRole.ADMIN) > -1;
}

export default function Auth () {
    const [userOrgIds, setUserOrgIds] = useState<User['orgIds']>();
    const { data: authData } = useStore(authStore);
    const pendingAuthData = useStore(pendingAuthStore);
    const navigate = useNavigate();

    function onSignInFailure() {
        alert('Sign in failure')
    }

    function handleChangeOrg(orgId) {
        userOrgIdReceived(orgId);
        navigate('/maps');
    }

    function handleUserOrgs(orgIds:User['orgIds']) {
        if (orgIds && Object.keys(orgIds).length === 1) {
            userOrgIdReceived(Object.keys(orgIds)[0]);
            navigate('/maps');
            return
        }
        setUserOrgIds(orgIds);
    }
    
    // if user is already logged in, don't show anything
    // console.trace('authdata', authData);
    if (authData) {
        return null;
    }

    // check for pending data
    if (!pendingAuthData?.user) {
        // no user yet
        return <FirebaseAuth onSignInSuccess={() => {}} onSignInFailure={onSignInFailure}/>;
    }

    // we have a pending user, but no orgId (otherwise authdata would have been set)
    // so check if we've gotten orgIds yet
    if (!userOrgIds) {
        return <GetOrgs onGet={handleUserOrgs} firebaseUser={pendingAuthData.user} />;
    } else {
        // orgIds were set but we're more than one so display changeOrg modal
        return <ChangeOrg user={pendingAuthData.user} onClick={handleChangeOrg}/>;
    }
}

export interface WithAuthProps {
    auth: AuthData
}

export const WithAuth = (Component) => (props) => {
    const auth = useStore(authStore);
    if (!auth.data?.orgId) {
        return <FullScreenLoader message='Authenticating' />
    }
    return <Component {...props} auth={auth.data}/>;
}

export interface WithOrgProps {
    org: Organization
}
// must be used with WithAuth component
export const WithOrg = Component => props => {
    const {auth}:{auth:AuthData} = props;
    const [org, setOrg] = useState<Organization>();
    // TODO add error handling and app wide notifcation
    useEffect(() => {
        getDoc(doc(getFirestore(), `/organizations/${auth.orgId}`)).then(snapshot => {
            return snapshot.data() as Organization;
        })
        .then(setOrg)
        .catch(e => {
            log.error('Error getting org data from withOrg.', e);
        });
    }, []);

    if (!org) {
        return <FullScreenLoader message='Getting organization data' />
    }
    return <Component {...props} org={org}/>;
}

export const WithCustomerPortalLink = Component => (props: {auth: AuthData }) => {
    const {auth} = props;
    const [link, setLink] = useState<string>();
    useEffect(() => {
        if (!auth.orgId) {
            return
        }
        getCustomerPortalLink({orgId: auth.orgId}).then((res) => {
            if (!res.data) { 
                return setLink('');
            }
                
            setLink(res.data);
        });
    }, []);

    // check for undefined as we set to null if record doesn't exist
    if (link === undefined) {
        return <FullScreenLoader message='Getting customer information.' />
    }
    return <Component {...props} customerPortalLink={link}/>;
}