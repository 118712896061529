import { faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { WithAuth, WithCustomerPortalLink, WithOrg } from '../../components/Auth';
import { AuthData } from '../../lib/auth/store';
import { Organization, UserRole } from '../../../functions/src/organization';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': { children: never[]; "pricing-table-id": string; "publishable-key": string; "client-reference-id": string; }
        }
    }
}

export enum AccountTab {
    general = 'general',
    billing = 'billing',
}

interface InfoProps {
    auth:AuthData
}
function GoogleInfo(props:InfoProps) {
    const { auth } = props;
    return (
        <>
            <div className="field">
                <label className="label has-text-primary">Name</label>
                <div className="control has-icons-left ">
                    <input className="input" type="text" value={auth.user.displayName || ''} disabled/>
                    <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={faUser} />
                    </span>
                </div>
                <p className="help is-info">This name is managed by Google</p>
            </div>

            <div className="field">
                <label className="label has-text-primary">Email</label>
                <div className="control has-icons-left has-icons-right">
                    <input className="input" type="email" value={auth.user.email || ''} disabled/>
                    <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    <span className="icon is-small is-right">
                    <i className="fas fa-exclamation-triangle"></i>
                    </span>
                </div>
                <p className="help is-info">This email is managed by Google</p>
            </div>

            <div className="field">
                <label className="label has-text-primary">Organization ID</label>
                <div className="control">
                    <input className="input" type="text" value={auth.orgId} disabled/>
                </div>
            </div>
        </>
    );
}

function EmailInfo(props:InfoProps) {
    const { auth } = props;
    return (
        <>
            <div className="field">
                <label className="label has-text-primary">Name</label>
                <div className="control has-icons-left ">
                    <input className="input" type="text" value={auth.user.displayName || ''} disabled/>
                    <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={faUser} />
                    </span>
                </div>
            </div>

            <div className="field">
                <label className="label has-text-primary">Email</label>
                <div className="control has-icons-left has-icons-right">
                    <input className="input" type="email" value={auth.user.email || ''} disabled/>
                    <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    <span className="icon is-small is-right">
                    <i className="fas fa-exclamation-triangle"></i>
                    </span>
                </div>
            </div>

            <div className="field">
                <label className="label has-text-primary">Organization ID</label>
                <div className="control">
                    <input className="input" type="text" value={auth.orgId} disabled/>
                </div>
            </div>
        </>
    );
}

interface BillingTabProps {
    email: string,
    pricingTableId: string,
    publishableKey: string,
    clientReferenceId: string
    customerPortalLink: string
}

function BillingTab(props:BillingTabProps) {
    const { pricingTableId, publishableKey, clientReferenceId, customerPortalLink } = props;
    if (customerPortalLink) {
        return (<div className='has-text-light'>
            {/* // eslint-disable-next-line max-len */}
            Mapnotes partners with Stripe to manage its billing. 
            You can Access the billing console <a
                href={customerPortalLink} title="Billing Console"
            >here.</a>
        </div>);
    }

    return (
        <stripe-pricing-table
            pricing-table-id={pricingTableId}
            publishable-key={publishableKey}
            client-reference-id={clientReferenceId}
        >
        </stripe-pricing-table>
    );
}

function LoadedBillingTab(props) {
    return WithAuth(WithCustomerPortalLink(BillingTab))(props);
} 

function capitalize(s)
{
    return s[0].toUpperCase() + s.slice(1);
}

function Account({auth}: {auth:AuthData}) {
    const { tab } = useParams();
    const selectedTab = tab as AccountTab
    const tabs = {
        [AccountTab.general]: () => isGoogle ? <GoogleInfo auth={auth}/> : <EmailInfo auth={auth} />
    }

    if (auth.roles.indexOf(UserRole.ADMIN) > -1) {
        tabs[AccountTab.billing] = () => {
            return <LoadedBillingTab
                publishableKey={process.env.STRIPE_PUBLISHABLE_KEY as string}
                pricingTableId={process.env.STRIPE_PRICING_TABLE_ID as string}
                clientReferenceId={auth.orgId}
            />
        }
    }

    // @ts-ignore - it's not clear why this is getting flagged
    const isGoogle = auth.user.providerData[0]?.providerId === 'google.com';
    const containerClasses = classNames('container page-body', {
        'mt-3': !isMobile,
        'mb-3': !isMobile,
        'm-3': isMobile
    });

    if (!tabs[selectedTab]) {
        return <Navigate to='/account' />;
    }

    return (
        <div className={containerClasses}>
            <div className='columns'>
                <div className="column is-3">
                    <aside className='menu'>
                        <p className="menu-label">
                            Account
                        </p>
                        <ul className="menu-list">
                            {
                                Object.keys(tabs).map((link) => (
                                    <Link
                                        to={`/account/${link}`}
                                        key={link}
                                        className={link === selectedTab ? 'is-active' : ''}
                                    >
                                        {capitalize(link)}
                                    </Link>
                                ))
                            }
                        </ul>
                    </aside>
                </div>
                <div className="column is-9">
                    {tabs[selectedTab]()}
                </div>
            </div>
            
        </div>
    );
}

export default WithAuth(Account);