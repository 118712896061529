
import * as firestore from "firebase/firestore"; 
import * as functions from "firebase/functions"; 
import * as firebaseAuth from "firebase/auth";
import { initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import * as storage from "firebase/storage";
import * as analyticsUtils from 'firebase/analytics';

const { getFirestore:getFirestoreBase } = firestore;
const { getAuth:getAuthBase, signOut: signOutBase } = firebaseAuth;
const { getFunctions: getFunctionsBase } = functions;
const { getStorage: getStorageBase } = storage;

const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID
};

let firebaseApp;
let analytics;

export function init() {
    firebaseApp = initializeApp(firebaseConfig);
    analytics = getAnalytics(firebaseApp);
}

export function logEvent(eventName, eventParams?: {
    page_title?: string;
    page_location?: string;
    page_path?: string;
    [key: string]: any;
}) {
    return analyticsUtils.logEvent(analytics, eventName, eventParams);
}

export function getFirestore() {
    if(!firebaseApp) {
        init();
    }
    return getFirestoreBase(firebaseApp);
}


export function getAuth() {
    if(!firebaseApp) {
        init();
    }
    return getAuthBase();
}

export function signOut() {
    if(!firebaseApp) {
        init();
    }
    return signOutBase(getAuthBase());
    // TODO signout error
}

export function getFunctions() {
    if(!firebaseApp) {
        init();
    }
    return getFunctionsBase(firebaseApp);
}

export function getStorage() {
    if(!firebaseApp) {
        init();
    }
    return getStorageBase(firebaseApp);
}

