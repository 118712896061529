import { useStore } from 'effector-react';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Auth from '../../components/Auth';

import Navbar from '../../components/MainNav';
import { authStore } from '../../lib/auth/store';

export default function Home() {
    const auth = useStore(authStore);
    const navigate = useNavigate();
    const message = auth.data?.user
        ? <span>View your <Link to="/maps">maps.</Link></span>
        : <span>Sign in now to try it.</span>
    return (
        <>
        <Navbar />
        <div className="container page-body">
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="columns is-centered">
                <div  className="notification is-primary">
                    This project is under beta. {message}
{/* Mapnotes is an application that allows uses to add markers and labels to serviceable areas on the map, highlight and color code specific areas for your technicians to be aware of, update avoidance areas in realtime for service technicians, easily see where you are in relation to other assets on the property, reduce onboarding time for new technicians or infrequent visitors to the property, and extract GPS coordinates directly from photos taken on the property and plot them on the map. {message} */}
                </div>
            </div>
            <br />
            <br />

            <Auth />
        </div>

        </>
    );
}

// add this to home page

// integrate new stripe account

// setup map tooltips



// Add markers and labels to serviceable areas on the map.

// Highlight and color code specific areas for your technicians to be aware of.

// Update avoidance areas in realtime for service technicians.

// Easily see where you are in relation to other assets on the property.

// Reduce onboarding time for new technicians or infrequent visitors to the property.

// Extract GPS coordinates directly from photos taken on the property and plot them on the map.