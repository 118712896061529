import React, { MutableRefObject, useEffect, useRef } from 'react';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl-style-switcher/styles.css';
import { v4 as uuidv4 } from 'uuid';
import { getCoords } from '@turf/invariant';


import mapboxgl, { LngLatLike } from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { combine, createEvent, createStore, restore, Store } from "effector";
import centroid from '@turf/centroid';
import * as memoize from 'lodash/memoize';
// @ts-ignore
import * as isEqual from 'lodash/isEqual';
import * as path from 'path';

import './Map.css';

import { MapAnnotation } from '../../functions/src/annotations';

import {  annotationPasteComplete, mapAnnotationsStore, mapDrawProgrammaticUpdate, serverDeletedAnnotationStore } from '../pages/mapEdit/store';
import { setMapCenter, mapImageCaptured } from '../pages/mapEdit/store';
import { menuAnnotationSelected, menuPositionChanged } from './MapMenu';
import { getDownloadURL, ref } from 'firebase/storage';
import { getStorage } from '../lib/firebase';
import { GeoJsonGeometryTypes, Point } from 'geojson';
import { queryParamsLoaded } from '../lib/appEvents';
import photoUpload, { annotationImageAdded } from '../mapControls/photoUpload';
import log from '../lib/log';

import getTheme from './theme';
import dropPin from '../mapControls/dropPin';

export enum MapControlPosition {
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
    BOTTOM_CENTER = 'bottom-center'
}

export interface DrawEvent{
    type: string,
    target: any // not used internally but comes from Mapbox
    features: MapAnnotation[]
}

export interface SearchResultEvent{
    result: {
        address: string,
        center: [number, number], //lng, lat
        geometry: GeoJsonGeometryTypes
        // there are more, but these are the only ones that matter at the moment
    }
}

interface BasicMapProps {
    mapRef: MutableRefObject<mapboxgl.Map | undefined>,
    onMove?: (center:[number, number]) => any
    onZoom?: (zoom:number) => any
    center: mapboxgl.LngLatLike,
    zoom: number,
    children?: React.ReactNode;
}

export interface MapProps extends BasicMapProps {
    id: string,
    // center: [number, number], // lng, lat 
    onDrawCreate: (event:DrawEvent) => any,
    onDrawDelete: (event:DrawEvent) => any,
    onDrawUpdate: (event:DrawEvent) => any,
    draw: MutableRefObject<MapboxDraw | undefined>
}

export interface SelectedFeatureIdStore {
    activeIds: {
        [id:string]: true
    }
    lastActiveIds: {
        [id:string]: true
    }
}

export const mapSelectionChange = createEvent<MapAnnotation[]>();
export const searchResultReceived = createEvent<SearchResultEvent>();
const isDraggingMapObject = createEvent<boolean>();
const isDraggingMapObjectStore = restore(isDraggingMapObject, false)
export const selectedFeatureIdStore = createStore<SelectedFeatureIdStore>({
    activeIds: {},
    lastActiveIds: {}
});

// TODO on delete, modify selectedfeatureid

selectedFeatureIdStore.on(mapSelectionChange, (state, mapAnnotations) => {
    log.debug('selectedFeatureIdStore:mapSelectionChange', mapAnnotations);

    const annotationLookup = mapAnnotations.reduce((lookup, annotation) => {
        lookup[annotation.id] = true;
        return lookup;
    }, {});

    if (isEqual(state.activeIds, annotationLookup)) {
        return state;
    }

    return {
        activeIds: annotationLookup,
        lastActiveIds: state.activeIds
    };
});

// select feature when menu item is selected
selectedFeatureIdStore.on(menuAnnotationSelected, (state, mapAnnotation) => {
    log.debug('selectedFeatureIdStore:menuAnnotationSelected');

    // if ID is already active, ignore
    if (Object.keys(state.activeIds).length === 1 && state.activeIds[mapAnnotation.id]) { 
        return state;
    }
    
    return {
        activeIds: {[mapAnnotation.id]: true},
        lastActiveIds: state.activeIds
    };
});

// IS THIS STILL NEEDED?
// // deselect when menu item is deselected
// selectedFeatureIdStore.on(menuAnnotationDeselect, (state) => {
//     log.debug('selectedFeatureIdStore:menuAnnotationDeselect');
//     return {
//         activeId: null,
//         lastActiveId: state.activeId
//     };
// });

selectedFeatureIdStore.on(queryParamsLoaded, (state, query) => {
    log.debug('selectedFeatureIdStore:queryParamsLoaded');
    if (query?.page !== 'mapEdit') {
        return state;
    }

    const lookup = (query.params?.annotationIds || [])
        .reduce((lookup, id) => {
            lookup[id] = true;
            return lookup;
    }, {});

    // if annotation is already active, ignore
    if ( isEqual(lookup, state.activeIds)) {
        return state;
    }

    return {
        activeIds: lookup,
        lastActiveIds: state.activeIds
    };
});

selectedFeatureIdStore.on(annotationImageAdded, (state, annotation) => {
    log.debug('selectedFeatureIdStore:annotationImageAdded');
    // if ID is already active, ignore
    if (Object.keys(state.activeIds).length === 1 && state.activeIds[annotation.id]) { 
        return state;
    }

    return {
        activeIds: {
            [annotation.id]: true
        },
        lastActiveIds: state.activeIds
    };
});

selectedFeatureIdStore.on(annotationPasteComplete, (state, ids) => {
    log.debug('selectedFeatureIdStore:annotationPasteComplete');
    const activeIds:SelectedFeatureIdStore['activeIds'] = ids;

    return {
        activeIds,
        lastActiveIds: state.activeIds
    };
});

// if something specifically needs access to a full annotation
// or needs to know if an annotation is fully loaded it can use this store
type SelectedFeatureStoreState = {[id:string]:MapAnnotation};
const selectedFeatureStoreState:SelectedFeatureStoreState = {};
export const selectedFeatureStore:Store<SelectedFeatureStoreState> = combine(
    mapAnnotationsStore,
    selectedFeatureIdStore,
    // @ts-ignore - effector works with objects https://effector.dev/docs/api/effector/combine
    selectedFeatureStoreState,
    (annotationStore, selectedId, state) => {
    // calculate combined "new" state
    // check equality with existing state object
    // if same, return state object to avoid a store update
    // if different, modify state object but return new object to trigger a store updte

    let newState:SelectedFeatureStoreState;
    if (!Object.keys(annotationStore).length) {
        // if annotations haven't loaded yet, ignore this
        newState = {};
    } else {
        const { activeIds } = selectedId;
        // make sure activeIds always map to annotations that are in the store
        newState = Object.keys(activeIds).reduce((lookup, id) => {
            const loadedAnnotation = annotationStore[id];
            if (!loadedAnnotation) {
                return lookup;
            }
    
            lookup[id] = loadedAnnotation;
            return lookup
        }, {});
    };

    if (isEqual(newState, state)) {
        return state;
    }

    state = newState;

    return newState;
});

const getDownloadURLMemo = memoize(getDownloadURL);

// const { MAPBOX_TOKEN } = process.env;
mapboxgl.accessToken = process.env.MAPBOX_TOKEN as string;

const imageToDataUri = (datas, newWidth, newHeight): Promise<string> => {
    const img = document.createElement('img');
    return new Promise(resolve => {
        img.onload = () => {
            const sx = (img.width - newWidth) / 2
            const sy = (img.height - newHeight) / 2
            const canvas = document.createElement("canvas"),
            ctx = canvas.getContext("2d");
        
            canvas.width = newWidth;
            canvas.height = newHeight;
        
            // @ts-ignore
            ctx.drawImage(img, sx, sy, newWidth + 100, newHeight + 100, 0, 0, newWidth, newHeight);

            resolve(canvas.toDataURL());
        };

        img.src = datas;
    });
  };

// observer to watch for when map class contains mouse-drag to know when
// user is dragging an object
const classObserver = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutationRecord) {
        // @ts-ignore
        if (mutationRecord.target.classList.contains('mouse-drag')) {
            isDraggingMapObject(true);
        } else {
            isDraggingMapObject(false);
        }
    });    
});

export function BasicMap(props:BasicMapProps) {
    const mapContainerRef = useRef(null);
    const {onMove = () => {}, center, zoom, onZoom = () => {} } = props;
    const mapRef = props.mapRef;
    const style = {
        width: '100%',
        height: '100%',
    };
    
    useEffect(() => {
        if (!mapContainerRef.current) {
            return;
        }
        // @ts-ignore
        const map = window.map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/satellite-v9', // style URL
            // center: center.current, // starting position [lng, lat]
            center, // starting position [lng, lat]
            zoom, // starting zoom,
            preserveDrawingBuffer: true
        });

        // window.map = map.current;

        // watch for class changes on the container
        // @ts-ignore
        classObserver.observe(mapContainerRef.current, { attributes : true, attributeFilter : ['class'] });

        map.on('move', (e) => {
            const center = map.getCenter();
            onMove([center.lng, center.lat]);

        });

        map.on('zoom', () => {
            const zoom = map.getZoom();
            onZoom(zoom);
        });

        mapRef.current = map;

        return () => {
            map.remove();
            classObserver.disconnect();
            // @ts-ignore
            delete window.map;
        }
    });

    return <div id="map" ref={mapContainerRef} style={style}>{props.children}</div>

}

export const WithGeocoder = (Component, position:MapControlPosition) => props => {
    const { uploadStoragePath, ...otherProps } = props;
    useEffect(() => {
        if (!props.mapRef.current) {
            return
        }

        const search = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            collapsed: true
        });

        search.on('result', (e) => {
            searchResultReceived(e);
        });


        props.mapRef.current.addControl(search, position);
    });

    return <Component {...otherProps} />   
};

export const WithGeolocate = (Component, position:MapControlPosition) => (props) => {
    useEffect(() => {
        if (!props.mapRef.current) {
            return
        }
        const geolocate = new mapboxgl.GeolocateControl({
            fitBoundsOptions: {
                maxZoom: props.zoom
            },
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true,
            showUserHeading: true
        });
        props.mapRef.current.addControl(geolocate, position);

        geolocate.on('geolocate', (position) => {
            props.onGeolocate && props.onGeolocate(position);
        });
        geolocate.on('trackuserlocationend', () => {
            props.onTrackuserlocationend && props.onTrackuserlocationend();
        });

    });

    return <Component {...props} />   
};

export const WithImageUpload = (Component, position:MapControlPosition) => props => {
    const { uploadStoragePath, ...otherProps } = props;
    useEffect(() => {
        if (!props.mapRef.current) {
            return
        }
        props.mapRef.current.addControl(photoUpload({uploadStoragePath}), position);
    });

    return <Component {...otherProps} />   
};

export const WithDropPin = Component => props => {
    const { onDropPinClick, ...otherProps } = props;
    useEffect(() => {
        if (!props.mapRef.current) {
            return
        }
        props.mapRef.current.addControl(dropPin({onClick: onDropPinClick}), 'top-left');
    });

    return <Component {...otherProps} />  
}

export const WithMenuPositionMonitor = (Component) => props => {
    useEffect(() => {
        if (!props.mapRef.current) {
            return
        }

        return menuPositionChanged.watch(position => {
            props.mapRef.current._container.style.height = `${position.y}px`;
            props.mapRef.current.resize();
        });
    });

    return <Component {...props} />   
};

function showImagePopup (map, annotation:MapAnnotation) {
    const imageRef = annotation.properties?.images && Object.keys(annotation.properties?.images)[0];

    if (!imageRef) {
        return Promise.resolve(null);
    }

    log.debug('annotation.properties?.images', annotation.properties?.images);

    // @ts-ignore - object cannot be undefined as we tested for it above
    const {width, height} = annotation.properties?.images[imageRef];
    const storage = getStorage();
    const fileName = path.basename(imageRef);
    const filePath = path.dirname(imageRef);
    const thumbFileName = `thumb_${fileName}`;
    const thumbFilePath = path.join(filePath, thumbFileName);
    const thumbRef = ref(storage, thumbFilePath);
    const xyRatio = (width && height) ? height / width: 1;
    const thumbWidth = 100;
    const thumbHeight = thumbWidth * xyRatio;

    // add popup for any images
    if (imageRef) {
        const popup = new mapboxgl.Popup({ closeOnClick: false, focusAfterOpen: false})
            .setLngLat((annotation.geometry as Point).coordinates as LngLatLike)
            .setHTML(`<div class="popup-image-placeholder" style="height:${thumbHeight}px;width:${thumbWidth}px"></div>`)
            .addTo(map.current);

        // don't wait for image to download
        getDownloadURLMemo(thumbRef).then(url => {
            popup.setHTML(
                `<img src='${url}' width='${thumbWidth}px' style='min-height:${thumbHeight}px' />`
            );
        });

        return Promise.resolve(popup);
    }

    return Promise.resolve(null);
}

interface DrawOptions {
    controls: {
        point: boolean,
        line_string: boolean,
        polygon: boolean,
        trash: boolean
    } | {}
}

export const WithDraw = (Component, options:DrawOptions, position:MapControlPosition) => (props:MapProps) => {
    const draw = props.draw || useRef<MapboxDraw>();
    const imagePopups = useRef<{[id:string]: mapboxgl.Popup | true}>({}); // true signifies pending load
    const map = props.mapRef;

    const { id } = props;

    // TODO, move names to higher z-index
    // fix polygon select to edit legs
    
    useEffect(() => {
        if (!map.current) {
            return;
        }
        draw.current = new MapboxDraw({
            displayControlsDefault: false,
            controls: options.controls,
            defaultMode: 'simple_select',
            userProperties: true,
            // styles: new MapboxDraw().options.styles.concat([{
            styles: getTheme().concat({
                // @ts-ignore id isn't part of layout standard but is used by draw
                id: 'symbols',
                type: 'symbol',
                layout: {
                    // get the title name from the source's "name" property
                    // draw converts this to "user_name"
                    'text-field': ['get', 'user_name'],
                    'text-font': [
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold'
                    ],
                    'text-size': 20,
                    'text-radial-offset': 1,
                    'text-anchor': ['case', ['has', 'user_textAnchor'], ['get', 'user_textAnchor'], 'top']
                },
                paint: {
                    // 'text-color': ['get', 'user_textColor', '#fff']
                    'text-color': ['case', ['has', 'user_textColor'], ['get', 'user_textColor'], '#fff'],
                    'text-halo-color': ['case', ['has', 'user_textHaloColor'], ['get', 'user_textHaloColor'], '#000'],
                    'text-halo-blur': 0,
                    'text-halo-width': 1
                }
            }
            // }, {
            //     id: 'fills',
            //     type: 'fill',
            //     layout: {},
            //     paint: {
            //         'fill-color': ['get', 'user_color'],
            //         'fill-opacity': 0.5,
            //     }
            // }, {
            //     'id': 'test',
            //     'type': 'line',
            //     'filter': ['all', ['==', '$type', 'Polygon']],
            //     'layout': {
            //     'line-cap': 'round',
            //     'line-join': 'round'
            //     },
            //     'paint': {
            //     'line-color': '#fff',
            //     'line-width': 2
            //     }
            // @ts-ignore - not sure why this is a problem
        )});
        // @ts-ignore
        map.current.addControl(draw.current, position);
        
        // map.current.addControl(new MapboxStyleSwitcherControl([
        //     {
        //         title: 'Streets',
        //         uri:'mapbox://styles/mapbox/streets-v11'
        //     },
        //     {
        //         title: 'Satellite',
        //         uri:'mapbox://styles/mapbox/satellite-v9'
        //     },
        //     {
        //         title: 'Streets Satellite',
        //         uri: 'mapbox://styles/mapbox/satellite-streets-v11'
        //     }
        // ]));

        map.current.on('draw.create', props.onDrawCreate);
        map.current.on('draw.delete', props.onDrawDelete);
        map.current.on('draw.update', props.onDrawUpdate);

        map.current.on('draw.selectionchange', (e) => {
            mapSelectionChange(e.features);
            if (!e.features.length){
                // deselect
                return;
            }
        });
    }, []);

    useEffect(() => {
        const subscriptions:any[] = [];

        // draw annotations from the annotation store
        subscriptions.push(mapAnnotationsStore.watch(annotations => {
            Object.values(annotations).forEach(annotation => {
                if (annotation.visible) {
                    return 
                }
                const dragState = isDraggingMapObjectStore.getState();
                const selectedFeatureIds = selectedFeatureIdStore.getState().activeIds;
                const isBeingDragged = dragState && selectedFeatureIds[annotation.id];
                // if we're dragging an active object that matches this annotation, ignore
                if (isBeingDragged) {
                    return;
                }

                log.debug('drawing annotation', annotation);
                // note: if properties change, we have to loop through them each to update
                // https://github.com/mapbox/mapbox-gl-draw/issues/878
                if (draw.current?.get(annotation.id)) {
                    Object.entries(annotation.properties).forEach(([key, value]) => {
                        // @ts-ignore
                        draw.current?.setFeatureProperty(annotation.id, key, value);
                    });
                }
                // @ts-ignore
                draw.current?.add(annotation);
                mapDrawProgrammaticUpdate(annotation);
            });
        }));

        // watch for annotations to delete
        subscriptions.push(serverDeletedAnnotationStore.watch(maybeEncodedAnnotation => {
            if (maybeEncodedAnnotation) {
                draw.current?.delete(maybeEncodedAnnotation.id);

                // note, popups are automatically removed when the feature is deselected
            }
        }));

        return () => {
            subscriptions.forEach(unsubcribe => unsubcribe())
        };
    }, []);

    useEffect(() => {
        // watch for features to be selected
        const unsub = selectedFeatureIdStore.watch((state) => {
            if (!draw.current) {
                return;
            }
            const {activeIds, lastActiveIds} = state;
            log.debug('selectedFeatureIdStore:watch', activeIds, lastActiveIds);
            // const activeKeys = Object.keys(active);
            const activeKeys = Object.keys(activeIds);
            const lastActiveKeys = Object.keys(lastActiveIds);
            // ignore initial load
            if(!activeKeys.length && !lastActiveKeys.length) {
                return;
            }

            // if there were active keys but are no longer, deactivate all
            if (lastActiveKeys.length && !activeKeys.length) {
                log.debug('deselecting annotations');
                draw.current.changeMode('simple_select', { featureIds: [] });
            }

            if (activeKeys.length) {
                // if ids are already selected, do nothing.
                // this means the user selected the items on the map and therefore we don't want to change anything
                if (isEqual(draw.current.getSelectedIds(), activeKeys)) {
                    return;
                }
                // select the feature on the map and fly to it
                log.debug('selecting ', activeKeys, draw.current.getSelectedIds());
                draw.current.changeMode('simple_select', { featureIds: activeKeys });
                
                // don't fly to the marker anymore unless it's specifically selected by the menu
                // map.current.flyTo({center: center.geometry.coordinates});
            }
        });

        return unsub;
    }, []);

    useEffect(() => {
        const unsub = selectedFeatureStore.watch(selectedFeatures => {

            Object.entries(selectedFeatures).filter(([activeId, annotation]) => {
                // if image is already visible, don't try to display it again
                return !imagePopups.current[activeId] && Object.keys(annotation.properties?.images || {}).length;
            }).forEach(([activeId, annotation]) => {
                // set to true for now so we don't accidentally write two popups on load
                imagePopups.current[activeId] = true;
                showImagePopup(map, annotation)
                    .then(popup => {
                        // then track our new instance
                        imagePopups.current[activeId] = popup;
                    });
                });

            // now loop through current popups and remove any that aren't selected
            Object.entries(imagePopups.current).filter(([id, popup])=> {
                return !selectedFeatures[id];
            }).forEach(([id, popup]) => {
                if ((popup as mapboxgl.Popup).remove) {
                    (popup as mapboxgl.Popup).remove()
                }
                delete imagePopups.current[id];
            });

        });

        return unsub;
    }, []);

    // when a menu item is selected, fly to it
    useEffect(() => {
        menuAnnotationSelected.watch(annotation => {
            if (!map.current) {
                return;
            }
            // @ts-ignore - conflict between geojson lib and turf lib
            const center = centroid(annotation.geometry);
            map.current.flyTo({center: center.geometry.coordinates as LngLatLike});
        });
    }, []);

    // listen for setMapCenter 
    // anytime center is set, take a screenshot
    useEffect(() => {
        const unsub = setMapCenter.watch(() => {
            if (!map.current) {
                return;
            }
            const img = map.current.getCanvas().toDataURL();
            imageToDataUri(img, 400, 300).then(i => {
                mapImageCaptured({id, img: i});
            });
        });

        return unsub;
    }, []);

    return (
        <Component {...props} draw={draw}/>
    );
}

const defaultDrawOptions = {
    controls: {}
};
const ComposedMap = (props:MapProps) => WithMenuPositionMonitor(
    WithImageUpload(
        WithGeolocate(
            WithGeocoder(
                WithDraw(
                    BasicMap,
                    defaultDrawOptions, MapControlPosition.BOTTOM_RIGHT
                ),
                MapControlPosition.TOP_LEFT
            ),
            MapControlPosition.TOP_RIGHT
        ),
        MapControlPosition.TOP_RIGHT
    )
)(props);

export default function Map(props:MapProps) {
    return <ComposedMap {...props}>
    </ComposedMap>
}

