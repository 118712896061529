import {PlanId, Plan} from './plan';
import {MapData} from './map';

// TODO expand user roles
export enum UserRole {
    ADMIN = 'admin',
    VIEWER = 'viewer'
}

export interface Organization extends UserOrgData {
    users: {
        [userId: string]: UserRole[]
    }
    maps?: {
        [mapId: string]: MapData
    }
    customerId?: string,
    subscriptionId?: string
}

// subset of org data that is safe to send to client
export interface UserOrgData {
    name: string,
    planId?: PlanId,
    restrictions?: Plan['restrictions']
    features?: Plan['features']
}
