import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createEvent } from "effector";
import exifr from "exifr";
import { ref, uploadBytes } from "firebase/storage";
import React from "react";
import ReactDOM from "react-dom/client";
import FileUploadWrapper from "../../components/FileUploadWrapper";
import { getStorage } from "../../lib/firebase";
import { ImageMetadata } from '../../../functions/src/images';
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from "react-device-detect";
import log from "../../lib/log";

interface ImageUploadError {
    fileName: string,
    error: Error
}

interface ImageUploadData {
    fileName: string
}

export const imageUploadStarted = createEvent<ImageUploadData>();
export const imageUploadComplete = createEvent<ImageUploadData>();
export const imageUploadError = createEvent<ImageUploadError>();
export const imageProcessingComplete = createEvent<ImageUploadData>();
export const annotationImageAdded = createEvent<{id: string}>();
export const imageUploadErrorDismissed = createEvent<ImageUploadError>();

const ACCEPTABLE_FORMATS = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/heic'
];

const ERROR_MISSING_GPS_METADATA = 'GPS data cannot be parsed from file.';
const ERROR_INCORRECT_FORMAT = 'File type must be JPEG, PNG, or HEIC.';

function handleUpload(fileName:string) {
    log.debug('imageUploadStarted', fileName);
    imageUploadStarted({fileName});
}

function handleUploadComplete(fileName:string) {
    log.debug('imageUploadComplete', fileName);
    imageUploadComplete({fileName});
}

function handleUploadError(fileName:string, error:Error) {
    log.debug('imageUploadError', fileName);
    imageUploadError({fileName, error});
}

const UploadButton = ({uploadStoragePath}) => {
    // TODO - change how this works
    // on successful processing, add annotation with image status of 'pending'
    // on post processing backend - scan image, process, and update status to 'available'
    async function handleChange(e:React.FormEvent<HTMLInputElement>) {
        // get file and send to firebase
        const storage = getStorage();
        const files:File[] | [] = Array.from((e.target as HTMLInputElement).files || []);
        // trigger upload event
        log.debug('uploading image', files);
        return Promise.all(files.map(file => {
            const fileExtension = file.name.split('.').pop();
            const filename = `${uuidv4()}.${fileExtension}`;
            const storagePath = `${uploadStoragePath}/${filename}`;
            const storageRef = ref(storage, storagePath);

            if (ACCEPTABLE_FORMATS.indexOf(file.type) === -1) {
                log.debug('imageupload:incorrect file format', file.type);
                return handleUploadError('', new Error(ERROR_INCORRECT_FORMAT));
            }

            return exifr.parse(file).then(data => {
                log.debug('exifr parsing complete', data);
                if (!data.latitude || !data.longitude) {
                    throw new Error(ERROR_MISSING_GPS_METADATA)
                }
                const metadata:ImageMetadata = {
                    contentType: file.type,
                    width: data.ImageWidth,
                    height: data.ImageHeight,
                    gpsAltitude: data.GPSAltitude,
                    gpsLatitude: data.latitude,
                    gpsLongitude: data.longitude
                }

                return metadata;
            }).then(metadata => {
                log.debug('metadata extracted', metadata);
                const uploadPromise = uploadBytes(storageRef, file, metadata)
                    .then(() => handleUploadComplete(filename));
                handleUpload(filename);
                return uploadPromise;
            }).catch(e => {
                log.debug('image parse/upload error', e);
                handleUploadError(filename, e);
            });
        }));
    }

    // if set, mobile browsers strip the gps metadata
    const accept = isMobile ? '' : ACCEPTABLE_FORMATS.join(',');

    return <FileUploadWrapper
            className='mapboxgl-ctrl-icon'
            accept={accept}
            onChange={handleChange}
        >
        
        {/* <span className='icon-text'>
            <span className="icon"> */}
                <FontAwesomeIcon icon={faImage} />
            {/* </span>
            <span>Upload photo as marker</span> */}
        {/* </span> */}
    </FileUploadWrapper>;

}

export default function photoUpload({uploadStoragePath}) {
    return {
        onAdd: () => {
            const node = document.createElement('div');
            const root = ReactDOM.createRoot(node);
            node.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
            root.render(UploadButton({uploadStoragePath}));

            return node;
        },
        onRemove: () => {
        }
    }
}