import * as React from 'react';
import { useRef } from 'react';


export default function FileUploadWrapper({onChange = (any) => {}, children, accept = '', ...otherProps}) {
    const inputRef = useRef<HTMLInputElement>(null);

    return <button onClick={() => {inputRef.current?.click()}} {...otherProps}>
        <input
            type='file'
            onChange={onChange}
            ref={inputRef}
            style={{display: 'none'}}
            accept={accept}
        />
        {children}
    </button>;
}