import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export const FullScreenLoader = ({message = 'Loading'}) => {
    const style = {
        // position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        minHeight: '300px',
        // background: '#fff',
        display: 'flex',
        zIndex: 1000,
        alignItems: 'center',
        justifyContent: 'center',
        margin: '20px auto'
    };

    return <div style={style} className=' has-text-light'>
        <span className='icon-text'>
            <span className='icon'>
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
            </span>
        </span>
        <span>{message}</span>
    </div>
}

// export const WithLoader = (Component, keyToListenFor) => (props) => {
//     const loading = !props[keyToListenFor];
//     return (
//         <>
//             <FullScreenLoader loading={loading} />
//             <Component {...props} />
//         </>
//     )
// }