import * as React from 'react';
import { Link } from 'react-router-dom';

import Navbar, { WithBrand, WithUserDropdown } from './Navbar';
// import logo from '../img/mapnotes.png';


function NavWithUser(props) {
  return WithUserDropdown(WithBrand(Navbar))(props);
}
export default function MainNav() {

  return (
    <NavWithUser
      menuStart={() => (
        <Link className="navbar-item" to="/maps">
          Maps
        </Link>
      )}
    />
  );
}