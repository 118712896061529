import {DEFAULT_MAP_LIMIT} from './constants';

import {Organization} from './organization';


export interface GetCustomerRecord {
    orgId: string
}

export function isNewMapAllowed(restrictions:Organization['restrictions'], collectionSize: number) {
    const mapLimit = restrictions?.mapLimit || DEFAULT_MAP_LIMIT;

    // -1 is unlimited maps
    if (mapLimit === -1) {
        return true;
    }

    return collectionSize < mapLimit;
}
