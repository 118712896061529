import * as React from 'react';
export function Input(props) {
    const {label, onChange, value, helpText, inputRef, ...otherProps} = props;
    const handleChange = (e) => {
        // setValue(e.target.value);
        onChange(e);
    }
    return (
        <div className="field">
            <label className="label">{label}</label>
            <div className="control">
                <input
                    className="input"
                    onChange={handleChange}
                    value={value}
                    ref={inputRef}
                    {...otherProps}
                />
            </div>
            {helpText && <p className="help">{helpText}</p>}
        </div>
    );
}