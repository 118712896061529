import * as React from 'react';
import { MapAnnotationProperties } from '../../functions/src/annotations';
import { hslToHex } from '../lib/colors';

const COLORS = [
    '#404040',
    '#fff',
    'hsl(217, 71%, 53%)',
    'hsl(204, 86%, 53%)',
    'hsl(141, 71%, 48%)',
    'hsl(48, 100%, 67%)',
    'hsl(348, 100%, 61%)'
].map(str => {
    // if already a hex, return
    if (str.indexOf('#') > -1) {
        return str;
    }
    const [all, h, s, l] = [...str.matchAll(/hsl\((.*), (.*)%, (.*)%\)/g)][0];
    return hslToHex(h,s,l);
});


interface ColorPaletteProps {
    onClick: (color) => any
    selected: MapAnnotationProperties['color']
}

export default function ColorPalette(props:ColorPaletteProps) {
    const {
        onClick,
        selected
    } = props;

    const palette = COLORS.map((color, index) => {
        // TODO - provide default option
        // if white color, set border
        const defaultBorder = index === 1 ? '2px solid #999' : 'none';
        const style = {
            background: color,
            border: (selected === color) ? '3px solid black' : defaultBorder
        };
        return (
            <div style={style} onClick={() => onClick(color)} key={color} className='color-palette-swatch'></div>
        );
    });

    return <>{palette}</>
}