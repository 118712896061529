import {Feature, Polygon} from 'geojson';

export interface EncodedImageOverlay {
    bucketUrl: string,
    bboxPoly: string
    center: number[],
    imageHeight: number,
    imageWidth: number,
    lastModified: number
}

export interface ImageOverlay extends Omit<EncodedImageOverlay, 'bboxPoly'>{
    bboxPoly: Feature<Polygon>,
    visible?: boolean,
    saved?: boolean,
    pendingSave?: boolean
}

export function encodeOverlay(overlay:ImageOverlay):EncodedImageOverlay {
    const {
        bucketUrl,
        bboxPoly,
        center,
        imageHeight,
        imageWidth,
        lastModified
    } = overlay;
    return {
        bucketUrl,
        bboxPoly: JSON.stringify(bboxPoly),
        center,
        imageHeight,
        imageWidth,
        lastModified
    };
}

export function decodeOverlay(overlay:EncodedImageOverlay):ImageOverlay {
    const {
        bucketUrl,
        bboxPoly,
        center,
        imageHeight,
        imageWidth,
        lastModified
    } = overlay;
    return {
        bucketUrl,
        bboxPoly: overlay.bboxPoly && JSON.parse(bboxPoly),
        center,
        imageHeight,
        imageWidth,
        lastModified
    };
}
